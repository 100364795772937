import client from '@/api/axiosClient'
import { IMonitoringHistoryParams } from '@/interfaces'

export const getListHistoryVideo = (params: IMonitoringHistoryParams) =>
  client.get('/monitors/video-history', {
    params,
  })

export const getListHistoryComment = (params: IMonitoringHistoryParams) =>
  client.get('/monitors/comment-history', {
    params,
  })
