import {
  Grid,
  Box,
  Paper,
  Switch,
  Stack,
  Typography,
  Button,
} from '@mui/material'
import {
  LightbulbOutlined,
  Air,
  ElectricalServices,
  DoorFront,
  AcUnit,
} from '@mui/icons-material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

const defaultValues = {
  light: false,
  fan: true,
  power_supply: true,
  door: false,
  air_conditioner: true,
}

interface IControlPanelProps {
  onSubmit: (data: any) => void
  setIsOpenComment: () => void
}

function ControlPanel({ onSubmit, setIsOpenComment }: IControlPanelProps) {
  const { t } = useTranslation()

  const { handleSubmit, control } = useForm({
    defaultValues,
  })

  const onSubmitProps = (data: any) => {
    onSubmit(data)
  }

  return (
    <Grid
      item
      xs={4}
      display="flex"
      justifyContent="center"
      className="control-panel"
    >
      <Paper
        elevation={3}
        sx={{ width: '55%', height: 'fit-content' }}
        component="form"
        onSubmit={handleSubmit(onSubmitProps)}
      >
        <Typography className="control-panel-title">
          {t('monitoring.box_details.facility_setting.title')}
        </Typography>
        <Stack direction="column" spacing={2} p={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" spacing={1}>
              <LightbulbOutlined />
              <Typography>
                {t('monitoring.box_details.facility_setting.light')}
              </Typography>
            </Stack>
            <Controller
              name="light"
              control={control}
              render={({ field }) => (
                <Switch
                  {...field}
                  onChange={e => {
                    field.onChange(e)
                    setIsOpenComment()
                  }}
                  checked={field.value}
                  id="light"
                  className="control-panel-switch"
                />
              )}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" spacing={1}>
              <Air />
              <Typography>
                {t('monitoring.box_details.facility_setting.fan')}
              </Typography>
            </Stack>
            <Controller
              name="fan"
              control={control}
              render={({ field }) => (
                <Switch
                  {...field}
                  onChange={e => {
                    field.onChange(e)
                    setIsOpenComment()
                  }}
                  checked={field.value}
                  id="fan"
                  className="control-panel-switch"
                />
              )}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" spacing={1}>
              <ElectricalServices />
              <Typography>
                {t('monitoring.box_details.facility_setting.power_supply')}
              </Typography>
            </Stack>
            <Controller
              name="power_supply"
              control={control}
              render={({ field }) => (
                <Switch
                  {...field}
                  onChange={e => {
                    field.onChange(e)
                    setIsOpenComment()
                  }}
                  checked={field.value}
                  id="power_supply"
                  className="control-panel-switch"
                />
              )}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" spacing={1}>
              <DoorFront />
              <Typography>
                {t('monitoring.box_details.facility_setting.door')}
              </Typography>
            </Stack>
            <Controller
              name="door"
              control={control}
              render={({ field }) => (
                <Switch
                  {...field}
                  onChange={e => {
                    field.onChange(e)
                    setIsOpenComment()
                  }}
                  checked={field.value}
                  id="door"
                  className="control-panel-switch"
                />
              )}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" spacing={1}>
              <AcUnit />
              <Typography>
                {t('monitoring.box_details.facility_setting.air_conditioner')}
              </Typography>
            </Stack>
            <Controller
              name="air_conditioner"
              control={control}
              render={({ field }) => (
                <Switch
                  {...field}
                  onChange={e => {
                    field.onChange(e)
                    setIsOpenComment()
                  }}
                  checked={field.value}
                  id="air_conditioner"
                  className="control-panel-switch"
                />
              )}
            />
          </Stack>
          <Box textAlign="center">
            <Button
              variant="contained"
              sx={{ borderRadius: 25, width: '50%' }}
              type="submit"
            >
              {t('monitoring.box_details.button.save')}
            </Button>
          </Box>
        </Stack>
      </Paper>
    </Grid>
  )
}

export default React.memo(ControlPanel)
