import React from 'react'

function Policy() {

  return (
    <>
      Policy
    </>
  )
}

export default React.memo(Policy)
