import en from '@/assets/images/flag_en.png'
import jp from '@/assets/images/flag_jp.png'

export const AUTH_USER_TOKEN = 'auth_user_token'

export const DEFAULT_LANGUAGE = 'ja'

export const LANGUAGES = [
  {
    lang: 'en',
    title: 'English',
  },
  {
    lang: 'ja',
    title: 'Japanese',
  },
]

export const LANGS = [
  {
    label: 'Japanese',
    value: 'ja',
    icon: jp,
  },
  {
    label: 'English',
    value: 'en',
    icon: en,
  },
]

export const LANGUAGE_STORE_KEY = 'by_current_lang'

export const DUMMY_VALUE = {
  ALL: 'all',
}

export const WS_PREFIX = 'monitor'

export const TYPE_MESSAGE = {
  SUCCESS: 'success',
  ERROR: 'error',
}
