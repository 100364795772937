import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import '@/assets/styles/pages/monitoring.scss'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@/redux/hooks'
import { useNavigate, generatePath } from 'react-router-dom'
import { ROUTER_PATH } from '@/constants'
import { IMonitoringBoxes } from '@/interfaces'

function FormFilter() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { listBoxes } = useAppSelector(state => state.monitoring)
  const statusBox = (data: IMonitoringBoxes): string => {
    if (data.is_prepare) {
      if (data.is_reservation)
        return `${t('monitoring.preparing')} (${t('monitoring.reservation')} ${
          data.start_time
        } - ${data.end_time})`
      return `${t('monitoring.preparing_no_reservation')}`
    }
    return `${t('monitoring.in_use')} (${data.start_time} - ${data.end_time})`
  }
  return (
    <Grid container spacing={4}>
      {listBoxes.map(item => (
        <Grid item xs={2} key={item.id} pl="0 !important" pr={4}>
          <Paper
            elevation={0}
            className="box-service-wrapper"
            onClick={() => {
              if (!item.is_repair)
                navigate(
                  generatePath(ROUTER_PATH.MONITORING_DETAILS, {
                    id: '1',
                  })
                )
            }}
          >
            <Box className="box-service">
              <Typography className="box-name">{item.box_name}</Typography>
              {item.is_repair ? (
                <Typography className="under-repair">
                  {t('monitoring.repair')}
                </Typography>
              ) : (
                <Typography className="time-use">{statusBox(item)}</Typography>
              )}
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

export default React.memo(FormFilter)
