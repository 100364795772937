import { useEffect, useState } from 'react'
import { SET_LOADING } from '@/redux/reducers/app.slice'
import { useAppDispatch } from '@/redux/hooks'
import { apiMaster } from '@/api/master'

function useMasterData(api: string) {
  const dispatch = useAppDispatch()

  const [listMasterData, setListMasterData] = useState<Array<any>>([])

  const getList = async (apiParams: string) => {
    try {
      dispatch(SET_LOADING(true))
      const res = await apiMaster(apiParams)
      setListMasterData(res.data)
    } catch (error) {
      setListMasterData([])
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  useEffect(() => {
    getList(api)
  }, [api])

  return listMasterData
}

export default useMasterData
