import { Box, Stack, Typography, Pagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import '@/assets/styles/pages/monitoring.scss'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from '@/redux/hooks'
import {
  FETCH_LIST_BOXES,
  FETCH_LIST_GROUP_1,
} from '@/redux/reducers/monitoring.slice'
import FormFilter from '@/components/Monitoring/ListBoxes/FormFilter'
import ListBoxes from '@/components/Monitoring/ListBoxes/ListBoxes'

interface IDefaultValues {
  group_1: number[]
  prefectures: number[]
  box_name: string
  group_2: number[]
  status: string
  from_date: string
  to_date: string
}

function Monitoring() {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const [isSearch, setIsSearch] = useState<boolean>(false)

  const onSubmit = () => {
    dispatch(FETCH_LIST_BOXES())
    // TODO console.log('data', data)
    // data: any
    setIsSearch(true)
  }

  useEffect(() => {
    dispatch(FETCH_LIST_BOXES())
    dispatch(FETCH_LIST_GROUP_1())
  }, [])

  const defaultValues: IDefaultValues = {
    group_1: [],
    prefectures: [],
    box_name: '',
    group_2: [],
    status: '',
    from_date: '',
    to_date: '',
  }

  const { handleSubmit, control } = useForm({
    defaultValues,
  })

  const handleChangePagination = () => {
    dispatch(FETCH_LIST_BOXES())
    // TODO console.log(value)
    // value: number
  }

  return (
    <Box p={1} sx={{ height: '100%' }}>
      <Typography mb={2} variant="h6" fontWeight="bold">
        {t('monitoring.title')}
      </Typography>
      <Box className="monitoring-container">
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ height: '100%' }}
          spacing={3}
        >
          <FormFilter
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            control={control}
          />
          <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            <Typography
              variant="h6"
              textAlign="center"
              className="text-result"
              fontWeight="bold"
            >
              {isSearch ? `120 ${t('monitoring.results')}` : ''}
            </Typography>
            <ListBoxes />
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={5}
              className="boxes-pagination"
            >
              <Typography>11 ~ 20</Typography>
              <Pagination
                count={10}
                color="primary"
                size="large"
                defaultPage={2}
                showFirstButton
                showLastButton
                onChange={handleChangePagination}
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}

export default React.memo(Monitoring)
