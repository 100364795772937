/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, Box, Paper, Switch, Stack, IconButton } from '@mui/material'
import {
  CallEnd,
  NoPhotography,
  Videocam,
  VideocamOff,
  VolumeOff,
  VolumeUp,
} from '@mui/icons-material'
import React, { useEffect, useRef, useState } from 'react'
import { IListCamera } from '@/interfaces'
import useFlashPhonerRoom from '@/hooks/useFlashPhonerRoom'
import { useAppSelector } from '@/redux/hooks'
import { constants } from '@flashphoner/websdk'

const { STREAM_STATUS } = constants
interface ICameraComponentProps {
  leaveCall: () => void
  isMicrophoneOn: boolean
  setIsMicrophoneOn: (data: boolean) => void
  isVideoOn: boolean
  setIsVideoOn: (data: boolean) => void
}

function CameraComponent({
  leaveCall,
  isMicrophoneOn,
  setIsMicrophoneOn,
  isVideoOn,
  setIsVideoOn,
}: ICameraComponentProps) {
  // const dispatch = useAppDispatch()

  const { roomName, isHaveCall } = useAppSelector(state => state.callVideo)

  const { currentRoom, participants } = useFlashPhonerRoom(roomName)

  const videoContainer = useRef(null)

  const videoParticipant = useRef(null)

  const videoParticipantZoom = useRef(null)

  const [stream, setStream] = useState<any>(null)

  const [streamParticipant, setStreamParticipant] = useState<any>(null)

  const [camera, setCamera] = useState<IListCamera[]>([
    {
      camera_id: 1,
      statusCamera: {
        status: true,
        isZoom: false,
      },
    },
    {
      camera_id: 2,
      statusCamera: {
        status: false,
        isZoom: false,
      },
    },
  ])

  const [videoCall, setVideoCall] = useState({
    isZoom: false,
    isOn: true,
  })

  useEffect(() => {
    if (currentRoom === null) return
    currentRoom
      .publish({
        display: videoContainer.current,
        constraints: {
          audio: true,
          video: true,
        },
        record: false,
        receiveVideo: false,
        receiveAudio: false,
      })
      .on(STREAM_STATUS.FAILED, () => {})
      .on(STREAM_STATUS.PUBLISHING, (data: any) => {
        setStream(data)
      })
      .on(STREAM_STATUS.UNPUBLISHED, (data: any) => {
        data.stop()
      })
  }, [currentRoom])

  const handleZoomInCamera = (indexCamera: number) => {
    const cameraClone = [...camera]
    cameraClone.forEach((item, index) => {
      item.statusCamera.isZoom = indexCamera === index
    })
    setCamera(cameraClone)
    setVideoCall({
      ...videoCall,
      isZoom: false,
    })
  }

  const handleZoomOutCamera = (indexCamera: number) => {
    const cameraClone = [...camera]
    cameraClone[indexCamera].statusCamera.isZoom = false
    setCamera(cameraClone)
  }

  const toggleCameraOn = (indexCamera: number) => {
    const cameraClone = [...camera]
    cameraClone[indexCamera].statusCamera.status = true
    setCamera(cameraClone)
  }

  const toggleCameraOff = (indexCamera: number) => {
    const cameraClone = [...camera]
    cameraClone[indexCamera].statusCamera.status = false
    setCamera(cameraClone)
  }

  const checkIsCameraZoom = () =>
    camera.some(cameraItem => cameraItem.statusCamera.isZoom) ||
    videoCall.isZoom

  useEffect(() => {
    if (!participants.length || !participants[0] || !participants[0].stream)
      return
    const player = participants[0].stream
    player
      .play(videoParticipant.current)
      .on(STREAM_STATUS.PLAYING, (data: any) => {
        setStreamParticipant(data)
      })
      .on(STREAM_STATUS.STOPPED, () => {})
      .on(STREAM_STATUS.FAILED, () => {})
    const playerZoom = participants[0].stream
    playerZoom.play(videoParticipantZoom.current)
  }, [participants])

  useEffect(() => {
    if (stream) {
      if (!isMicrophoneOn) stream.muteAudio()
      if (!isVideoOn) stream.muteVideo()
    }
  }, [stream])

  return (
    <Grid container spacing={2}>
      <Grid item xs={checkIsCameraZoom() ? 6 : 12}>
        <Grid container spacing={2}>
          {camera.map((item, index) => {
            if (!item.statusCamera.isZoom)
              return (
                <Grid
                  item
                  xs={checkIsCameraZoom() ? 6 : 4}
                  key={item.camera_id}
                >
                  <Paper elevation={0} className="box-service-wrapper">
                    {item.statusCamera.status ? (
                      <>
                        <Box
                          className="box-service"
                          onClick={() => handleZoomInCamera(index)}
                        />
                        <Box className="btn-switch">
                          <Switch
                            className="box-service-switch"
                            checked={item.statusCamera.status}
                            onChange={() => toggleCameraOff(index)}
                          />
                        </Box>
                      </>
                    ) : (
                      <Box className="box-service-no-camera">
                        <Box
                          className="icon-no-camera"
                          onClick={() => handleZoomInCamera(index)}
                        >
                          <NoPhotography />
                        </Box>
                        <Switch
                          className="box-service-switch"
                          checked={item.statusCamera.status}
                          onChange={() => toggleCameraOn(index)}
                        />
                      </Box>
                    )}
                  </Paper>
                </Grid>
              )
            return <Box key={item.camera_id} />
          })}
          <Grid
            item
            xs={checkIsCameraZoom() ? 6 : 4}
            sx={videoCall.isZoom ? { display: 'none !important' } : {}}
          >
            <Paper elevation={0} className="box-service-wrapper">
              <Box
                className="box-service"
                onClick={() => {
                  handleZoomInCamera(-1)
                  setVideoCall({
                    ...videoCall,
                    isZoom: true,
                  })
                }}
                sx={
                  videoCall.isOn
                    ? {
                        padding: '0 !important',
                        backgroundImage: 'unset !important',
                      }
                    : {
                        padding: '0 !important',
                        backgroundImage: 'unset !important',
                        display: 'none !important',
                      }
                }
              >
                {isHaveCall && (
                  <div ref={videoParticipant} className="participant-webcam" />
                )}
              </Box>
              <Box className="btn-switch">
                <Switch
                  className="box-service-switch"
                  checked={videoCall.isOn}
                  onChange={() => {
                    setVideoCall({
                      ...videoCall,
                      isOn: !videoCall.isOn,
                    })
                    if (videoCall.isOn) streamParticipant.muteVideo()
                    else streamParticipant.unmuteVideo()
                  }}
                />
              </Box>
              <Box
                className="box-service-no-camera"
                sx={
                  videoCall.isOn
                    ? {
                        padding: '0 !important',
                        backgroundImage: 'unset !important',
                        display: 'none !important',
                      }
                    : {
                        padding: '0 !important',
                        backgroundImage: 'unset !important',
                      }
                }
              >
                <Box
                  className="icon-no-camera"
                  onClick={() => {
                    handleZoomInCamera(-1)
                    setVideoCall({
                      ...videoCall,
                      isZoom: true,
                    })
                  }}
                >
                  <NoPhotography />
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Box className="video-call">
              {isHaveCall && <div ref={videoContainer} className="my-webcam" />}
              <Stack direction="row" spacing={3} mb={3} position="absolute">
                {isVideoOn ? (
                  <IconButton
                    className="button-camera"
                    onClick={() => {
                      setIsVideoOn(false)
                      stream.muteVideo()
                    }}
                  >
                    <Videocam />
                  </IconButton>
                ) : (
                  <IconButton
                    className="button-camera"
                    onClick={() => {
                      setIsVideoOn(true)
                      stream.unmuteVideo()
                    }}
                  >
                    <VideocamOff />
                  </IconButton>
                )}

                {isMicrophoneOn ? (
                  <IconButton
                    className="button-volume"
                    onClick={() => {
                      setIsMicrophoneOn(false)
                      stream.muteAudio()
                    }}
                  >
                    <VolumeUp />
                  </IconButton>
                ) : (
                  <IconButton
                    className="button-volume"
                    onClick={() => {
                      setIsMicrophoneOn(true)
                      stream.unmuteAudio()
                    }}
                  >
                    <VolumeOff />
                  </IconButton>
                )}
                <IconButton
                  className="button-end-call"
                  onClick={() => {
                    currentRoom.leave().then(() => leaveCall())
                  }}
                >
                  <CallEnd />
                </IconButton>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {camera.map((item, index) => {
        if (item.statusCamera.isZoom)
          return (
            <Grid item xs={6} key={item.camera_id}>
              <Paper elevation={0} className="box-service-wrapper">
                {item.statusCamera.status ? (
                  <>
                    <Box
                      className="box-service"
                      onClick={() => handleZoomOutCamera(index)}
                    />
                    <Box className="btn-switch">
                      <Switch
                        className="box-service-switch"
                        checked={item.statusCamera.status}
                        onChange={() => toggleCameraOff(index)}
                      />
                    </Box>
                  </>
                ) : (
                  <Box className="box-service-no-camera">
                    <Box
                      className="icon-no-camera"
                      onClick={() => handleZoomOutCamera(index)}
                    >
                      <NoPhotography />
                    </Box>
                    <Switch
                      className="box-service-switch"
                      checked={item.statusCamera.status}
                      onChange={() => toggleCameraOn(index)}
                    />
                  </Box>
                )}
              </Paper>
            </Grid>
          )
        return <Box key={item.camera_id} />
      })}
      <Grid
        item
        xs={6}
        sx={!videoCall.isZoom ? { display: 'none !important' } : {}}
      >
        <Paper elevation={0} className="box-service-wrapper">
          <Box
            className="box-service"
            onClick={() => {
              handleZoomInCamera(-1)
              setVideoCall({
                ...videoCall,
                isZoom: false,
              })
            }}
            sx={
              videoCall.isOn
                ? {
                    padding: '0 !important',
                    backgroundImage: 'unset !important',
                  }
                : {
                    padding: '0 !important',
                    backgroundImage: 'unset !important',
                    display: 'none !important',
                  }
            }
          >
            {isHaveCall && (
              <div
                ref={videoParticipantZoom}
                className="participant-webcam-zoom"
              />
            )}
          </Box>
          <Box className="btn-switch">
            <Switch
              className="box-service-switch"
              checked={videoCall.isOn}
              onChange={() => {
                setVideoCall({
                  ...videoCall,
                  isOn: !videoCall.isOn,
                })
                if (videoCall.isOn) streamParticipant.muteVideo()
                else streamParticipant.unmuteVideo()
              }}
            />
          </Box>
          <Box
            className="box-service-no-camera"
            sx={
              videoCall.isOn
                ? {
                    padding: '0 !important',
                    backgroundImage: 'unset !important',
                    display: 'none !important',
                  }
                : {
                    padding: '0 !important',
                    backgroundImage: 'unset !important',
                  }
            }
          >
            <Box
              className="icon-no-camera"
              onClick={() => {
                handleZoomInCamera(-1)
                setVideoCall({
                  ...videoCall,
                  isZoom: false,
                })
              }}
            >
              <NoPhotography />
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default React.memo(CameraComponent)
