import { all, takeLatest, put, ForkEffect, call } from 'redux-saga/effects'
import {
  FETCH_MONITOR_DETAIL,
  SET_MONITOR_DETAIL,
  FETCH_LOWER_ROLES,
  SET_LOWER_ROLES,
  FETCH_MANAGERS,
  SET_MANAGERS,
  FETCH_GROUP_1,
  SET_GROUP_1,
  FETCH_GROUP_2,
  SET_GROUP_2,
  CREATE_MONITOR,
  EDIT_MONITOR,
  DELETE_MONITOR,
  FETCH_MONITOR_LIST,
  SET_MONITOR_LIST,
  FETCH_GROUP,
  SET_AVAILABLE_GROUP,
} from '@/redux/reducers/monitor.slice'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { handleMessageError } from '@/utils'
import { AxiosResponse } from 'axios'
import {
  apiGetMonitorDetail,
  apiCreateMonitor,
  apiGetGroup1,
  apiGetGroup2,
  apiGetLowerRole,
  apiGetManager,
  apiGetMonitorList,
  apiQueryGetGroup1,
  apiQueryGetGroup2,
  apiEditMonitor,
  apiDeleteMonitor,
} from '@/api/monitor'
import { PayloadAction } from '@reduxjs/toolkit'
import { ICreateMonitor, IGroup } from '@/interfaces'
import { MESSAGE_CONTENT } from '@/constants'

function* getMonitorDetail(action: PayloadAction<string | number>) {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    const detailRes: AxiosResponse = yield call(
      apiGetMonitorDetail,
      action.payload
    )
    yield put(SET_MONITOR_DETAIL(detailRes.data))
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getLowerRoles() {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    const roleRes: AxiosResponse = yield call(apiGetLowerRole)
    yield put(SET_LOWER_ROLES(roleRes.data))
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getManagers(action: PayloadAction<string | number>) {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    const managerRes: AxiosResponse = yield call(apiGetManager, action.payload)
    yield put(SET_MANAGERS(managerRes.data))
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getGroup1(action: PayloadAction<string | number>) {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    const group1Res: AxiosResponse = yield call(apiGetGroup1, action.payload)
    yield put(SET_GROUP_1(group1Res.data))
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}
function* getGroup2(
  action: PayloadAction<{
    managerId: string | number | undefined
    boxes1: number[]
  }>
) {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    const group2Res: AxiosResponse = yield call(apiGetGroup2, action.payload)
    yield put(SET_GROUP_2(group2Res.data))
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}
function* createMonitor(action: PayloadAction<ICreateMonitor>) {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    yield call(apiCreateMonitor, action.payload)
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: MESSAGE_CONTENT.CREATE_SUCCESS,
      })
    )
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getMonitorList(action: PayloadAction<any>) {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    const listRes: AxiosResponse = yield call(apiGetMonitorList, action.payload)
    yield put(SET_MONITOR_LIST(listRes.data))
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getGroup() {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    const group1Res: AxiosResponse = yield call(apiQueryGetGroup1)
    const group2 = group1Res.data.map((group: IGroup) => group.id)
    const group2Res: AxiosResponse = yield call(apiQueryGetGroup2, group2)
    yield put(
      SET_AVAILABLE_GROUP({
        group1: group1Res.data,
        group2: group2Res.data.data,
      })
    )
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}
function* editMonitor(action: PayloadAction<any>) {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    yield call(apiEditMonitor, action.payload)
    yield put(FETCH_MONITOR_DETAIL(action.payload.id))
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: MESSAGE_CONTENT.EDIT_SUCCESS,
      })
    )
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}
function* deleteMonitor(action: PayloadAction<string | undefined>) {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    yield call(apiDeleteMonitor, action.payload)
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: MESSAGE_CONTENT.DELETE_SUCCESS,
      })
    )
    yield put(FETCH_MONITOR_LIST({}))
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

export default function* monitorSaga() {
  const filteredSagas: ForkEffect[] = [
    takeLatest(FETCH_MONITOR_DETAIL, getMonitorDetail),
    takeLatest(FETCH_LOWER_ROLES, getLowerRoles),
    takeLatest(FETCH_MANAGERS, getManagers),
    takeLatest(FETCH_GROUP_1, getGroup1),
    takeLatest(FETCH_GROUP_2, getGroup2),
    takeLatest(CREATE_MONITOR, createMonitor),
    takeLatest(EDIT_MONITOR, editMonitor),
    takeLatest(DELETE_MONITOR, deleteMonitor),
    takeLatest(FETCH_MONITOR_LIST, getMonitorList),
    takeLatest(FETCH_GROUP, getGroup),
  ]
  yield all(filteredSagas)
}
