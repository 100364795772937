import { ICallVideo } from '@/interfaces'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: ICallVideo = {
  isHaveCall: false,
  roomName: '',
  isCalling: false,
}

export const callVideoSlice = createSlice({
  name: 'callVideo',
  initialState,
  reducers: {
    PICKUP_A_CALL: (state, action: PayloadAction<string>) => {
      state.isHaveCall = true
      state.roomName = action.payload
    },
    LEAVE_CALL: state => {
      state.isHaveCall = false
    },
    HANDLE_CALL: (state, action: PayloadAction<boolean>) => {
      state.isCalling = action.payload
    },
  },
})

// Actions
export const { PICKUP_A_CALL, LEAVE_CALL, HANDLE_CALL } = callVideoSlice.actions

// Reducer
export default callVideoSlice.reducer
