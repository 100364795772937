import '@/assets/styles/pages/monitoring-history.scss'
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Box, Stack, Button, TextField } from '@mui/material'
import { DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import Table from 'antd/es/table'
import Typography from 'antd/es/typography'
import { ColumnsType } from 'antd/lib/table/interface'
import {
  IMonitoringHistoryComment,
  IMonitoringHistoryParams,
} from '@/interfaces'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { FETCH_HISTORY_COMMENT } from '@/redux/reducers/monitoring.slice'
import { showTotalPaginationTable } from '@/utils'

function TabComment() {
  const { Paragraph } = Typography

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { listHistoryComment, metaHistoryComment } = useAppSelector(
    state => state.monitoring
  )

  const [queryParams, setQueryParams] = useState<IMonitoringHistoryParams>({
    id: '2',
    date_from: '',
    date_to: '',
    page: 1,
    search_name: '',
    search_content: '',
  })

  const onSubmit = (data: any) => {
    setQueryParams({
      ...queryParams,
      ...data,
      date_from:
        data.date_from !== '' ? dayjs(data.from_date).format('YYYY-MM-DD') : '',
      date_to:
        data.date_to !== '' ? dayjs(data.to_date).format('YYYY-MM-DD') : '',
    })
  }

  const [startTime, setStartTime] = useState<Date | null>(null)

  const [endTime, setEndTime] = useState<Date | null>(null)

  useEffect(() => {
    dispatch(FETCH_HISTORY_COMMENT(queryParams))
  }, [queryParams])

  const defaultValues = {
    date_from: '',
    date_to: '',
    search_name: '',
    search_content: '',
  }

  const { handleSubmit, control } = useForm({
    defaultValues,
  })

  const columns: ColumnsType<IMonitoringHistoryComment> = [
    {
      title: t('monitoring_history.date_time'),
      key: 'created_at',
      align: 'center',
      render: (_, record) =>
        dayjs(record.created_at).format('HH:mm YYYY/MM/DD'),
    },
    {
      title: t('monitoring_history.manager'),
      dataIndex: 'monitor_name',
      key: 'monitor_name',
      align: 'center',
    },
    {
      title: t('monitoring_history.comment'),
      key: 'content',
      width: '50%',
      render: (_, record) => (
        <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: '>>' }}>
          {record.content}
        </Paragraph>
      ),
    },
  ]

  const handlePagination = (pagination: any) => {
    setQueryParams({ ...queryParams, page: pagination.current })
  }

  return (
    <Stack direction="column" spacing={3} pl={3} pr={3}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" spacing={3} alignItems="center" p={1}>
          <Stack direction="row" spacing={3} width="100%">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                name="date_from"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onChange={e => {
                      field.onChange(e)
                      setStartTime(e)
                    }}
                    label={t('monitoring_history.from_date')}
                    maxDate={endTime || new Date()}
                    renderInput={params => (
                      <TextField {...params} error={false} />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
            <Box display="flex" alignItems="center">
              ~
            </Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                name="date_to"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onChange={e => {
                      field.onChange(e)
                      setEndTime(e)
                    }}
                    label={t('monitoring_history.to_date')}
                    minDate={startTime}
                    maxDate={new Date()}
                    renderInput={params => (
                      <TextField {...params} error={false} />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
          <Box width="50%">
            <Controller
              name="search_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('monitoring_history.manager')}
                  value={field.value}
                  inputProps={{ maxLength: 25 }}
                />
              )}
            />
          </Box>
          <Box width="50%">
            <Controller
              name="search_content"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('monitoring_history.comment')}
                  value={field.value}
                  inputProps={{ maxLength: 500 }}
                />
              )}
            />
          </Box>
          <Box width="30%">
            <Button
              type="submit"
              variant="contained"
              sx={{ borderRadius: 20 }}
              fullWidth
            >
              {t('monitoring_history.search')}
            </Button>
          </Box>
        </Stack>
      </Box>
      <Table
        columns={columns}
        dataSource={listHistoryComment}
        scroll={{ y: 515 }}
        onChange={handlePagination}
        pagination={{
          total: metaHistoryComment.total,
          showTotal: () =>
            showTotalPaginationTable(
              metaHistoryComment.per_page,
              metaHistoryComment.current_page,
              listHistoryComment.length,
              metaHistoryComment.total
            ),
        }}
      />
    </Stack>
  )
}

export default React.memo(TabComment)
