import { IMonitor } from '@/interfaces'
import dayjs from 'dayjs'

export const DUMMY_MONITOR: IMonitor = {
  id: 0,
  email: '',
  tel: '',
  name: '',
  sex: 1,
  birthday: dayjs().format('YYYY/MM/DD'),
  address: '',
  memo: '',
  status: 0,
  role: 4,
  parent_id: '',
  parent_name: '',
  login_failed: 0,
  deleted_at: '',
  created_at: '',
  updated_at: '',
  avatar: '',
  upload_id: 0,
  group_boxes1: [
    {
      id: undefined,
      name: '',
      parent_id: '',
      deleted_at: '',
      created_at: '',
      updated_at: '',
      group_box2: [
        {
          id: undefined,
          name: '',
          parent_id: '',
          deleted_at: '',
          created_at: '',
          updated_at: '',
        },
      ],
    },
  ],
  group_boxes: [
    {
      id: undefined,
      name: '',
      parent_id: '',
      deleted_at: '',
      created_at: '',
      updated_at: '',
    },
  ],
}
