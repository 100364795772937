import { Stack, Box, IconButton, Typography, Avatar } from '@mui/material'
import {
  CallEnd,
  Videocam,
  VideocamOff,
  VolumeOff,
  VolumeUp,
} from '@mui/icons-material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export interface ICallingComponentProps {
  cancelCalling: () => void
  isMicrophoneOn: boolean
  setIsMicrophoneOn: (isOn: boolean) => void
  isVideoOn: boolean
  setIsVideoOn: (isOn: boolean) => void
}

function CallingComponent({
  cancelCalling,
  isMicrophoneOn,
  setIsMicrophoneOn,
  isVideoOn,
  setIsVideoOn,
}: ICallingComponentProps) {
  const { t } = useTranslation()

  return (
    <Box className="calling-overlay">
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        height="100%"
      >
        <Typography>{t('monitoring.box_details.outgoing')}</Typography>
        <Avatar
          alt="Remy Sharp"
          src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
          sx={{ width: 200, height: 200 }}
        />
        <Typography>{t('monitoring.box_details.user')} ID XYZ</Typography>
        <Stack direction="row" spacing={3} mb={3}>
          {isVideoOn ? (
            <IconButton
              className="button-camera"
              onClick={() => setIsVideoOn(false)}
            >
              <Videocam />
            </IconButton>
          ) : (
            <IconButton
              className="button-camera"
              onClick={() => setIsVideoOn(true)}
            >
              <VideocamOff />
            </IconButton>
          )}

          {isMicrophoneOn ? (
            <IconButton
              className="button-volume"
              onClick={() => setIsMicrophoneOn(false)}
            >
              <VolumeUp />
            </IconButton>
          ) : (
            <IconButton
              className="button-volume"
              onClick={() => setIsMicrophoneOn(true)}
            >
              <VolumeOff />
            </IconButton>
          )}

          <IconButton
            className="button-end-call"
            onClick={() => cancelCalling()}
          >
            <CallEnd />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  )
}

export default React.memo(CallingComponent)
