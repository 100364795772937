import React from 'react'
import PublicRoute from '@/components/common/PublicRoute'
import BasicLayout from '@/layout/BasicLayout'
import { RouteObject } from 'react-router-dom'
import SignIn from '@/pages/Auth/SignIn'
import ForgotPassword from '@/pages/Auth/ForgotPassword'
import ResetPassword from '@/pages/Auth/ResetPassword'
import Policy from '@/pages/Policy'

const PublicRouter: RouteObject = {
  path: '/',
  element: (
    <PublicRoute>
      <BasicLayout />
    </PublicRoute>
  ),
  children: [
    {
      index: true,
      path: 'sign-in',
      element: <SignIn />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: 'reset-password',
      element: <ResetPassword />,
    },
    {
      path: 'policy',
      element: <Policy />,
    },
  ],
}

export default PublicRouter
