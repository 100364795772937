import { all, takeLatest, put, ForkEffect } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  FETCH_COMMENT_BOX_DETAILS,
  FETCH_COMMENT_PLAYBACK,
  FETCH_HISTORY_COMMENT,
  FETCH_HISTORY_VIDEO,
  FETCH_LIST_BOXES,
  FETCH_LIST_GROUP_1,
  FETCH_LIST_GROUP_2,
  FETCH_LIST_PREFECTURES,
  SET_COMMENT_BOX_DETAILS,
  SET_COMMENT_PLAYBACK,
  SET_HISTORY_COMMENT,
  SET_HISTORY_VIDEO,
  SET_LIST_BOXES,
  SET_LIST_GROUP_1,
  SET_LIST_GROUP_2,
  SET_LIST_PREFECTURES,
} from '@/redux/reducers/monitoring.slice'
import { SET_LOADING, SET_MESSAGE } from '@/redux/reducers/app.slice'
import { handleMessageError } from '@/utils'
import { IMonitoringHistoryParams } from '@/interfaces'
import { getListHistoryComment, getListHistoryVideo } from '@/api/monitoring'

function* getListMonitoringHistoryVideo(
  action: PayloadAction<IMonitoringHistoryParams>
) {
  try {
    yield put(SET_LOADING(true))
    const { payload } = action
    const response: AxiosResponse = yield getListHistoryVideo(payload)
    yield put(SET_HISTORY_VIDEO(response.data))
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: 'fetch_data_success',
      })
    )
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getListMonitoringHistoryComment(
  action: PayloadAction<IMonitoringHistoryParams>
) {
  try {
    yield put(SET_LOADING(true))
    const { payload } = action
    const response: AxiosResponse = yield getListHistoryComment(payload)
    yield put(SET_HISTORY_COMMENT(response.data))
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: 'fetch_data_success',
      })
    )
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getListMonitoringBoxes() {
  // TODO action: PayloadAction<IMonitoringHistoryParams>
  try {
    yield put(SET_LOADING(true))
    // TODO const { payload } = action
    // TODO const response: AxiosResponse = yield apiUserSignIn(payload)
    yield put(SET_LIST_BOXES())
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: 'fetch_data_success',
      })
    )
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getListMonitoringCommentPlayback() {
  //   action: PayloadAction<IMonitoringHistoryParams>
  try {
    yield put(SET_LOADING(true))
    // TODO const { payload } = action
    // TODO const response: AxiosResponse = yield apiUserSignIn(payload)
    yield put(SET_COMMENT_PLAYBACK())
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getListMonitoringCommentBoxDetails() {
  //   action: PayloadAction<IMonitoringHistoryParams>
  try {
    yield put(SET_LOADING(true))
    // TODO const { payload } = action
    // TODO const response: AxiosResponse = yield apiUserSignIn(payload)
    yield put(SET_COMMENT_BOX_DETAILS())
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getListMonitoringGroup1() {
  // TODO action: PayloadAction<IMonitoringHistoryParams>
  try {
    yield put(SET_LOADING(true))
    // TODO const { payload } = action
    // TODO const response: AxiosResponse = yield apiUserSignIn(payload)
    yield put(SET_LIST_GROUP_1())
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: 'fetch_data_success',
      })
    )
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getListMonitoringGroup2() {
  // TODO action: PayloadAction<IMonitoringHistoryParams>
  try {
    yield put(SET_LOADING(true))
    // TODO const { payload } = action
    // TODO const response: AxiosResponse = yield apiUserSignIn(payload)
    yield put(SET_LIST_GROUP_2())
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: 'fetch_data_success',
      })
    )
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getListMonitoringPrefectures() {
  // TODO action: PayloadAction<IMonitoringHistoryParams>
  try {
    yield put(SET_LOADING(true))
    // TODO const { payload } = action
    // TODO const response: AxiosResponse = yield apiUserSignIn(payload)
    yield put(SET_LIST_PREFECTURES())
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: 'fetch_data_success',
      })
    )
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

export default function* monitoringSaga() {
  const filteredSagas: ForkEffect[] = [
    takeLatest(FETCH_HISTORY_VIDEO, getListMonitoringHistoryVideo),
    takeLatest(FETCH_HISTORY_COMMENT, getListMonitoringHistoryComment),
    takeLatest(FETCH_LIST_BOXES, getListMonitoringBoxes),
    takeLatest(FETCH_LIST_GROUP_1, getListMonitoringGroup1),
    takeLatest(FETCH_LIST_GROUP_2, getListMonitoringGroup2),
    takeLatest(FETCH_LIST_PREFECTURES, getListMonitoringPrefectures),
    takeLatest(FETCH_COMMENT_PLAYBACK, getListMonitoringCommentPlayback),
    takeLatest(FETCH_COMMENT_BOX_DETAILS, getListMonitoringCommentBoxDetails),
  ]

  yield all(filteredSagas)
}
