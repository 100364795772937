import {
  Box,
  Grid,
  Stack,
  MenuItem,
  Button,
  Paper,
  TextField,
  Select,
  ListItemText,
  Checkbox,
  InputLabel,
  FormControl,
} from '@mui/material'
import React, { useState } from 'react'
import '@/assets/styles/pages/monitoring.scss'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import SelectCommon from '@/components/common/SelectCommon'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  FETCH_LIST_GROUP_2,
  FETCH_LIST_PREFECTURES,
} from '@/redux/reducers/monitoring.slice'

function FormFilter({ handleSubmit, onSubmit, control }: any) {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const [startTime, setStartTime] = useState<Date | null>(null)

  const [endTime, setEndTime] = useState<Date | null>(null)

  const { listGroup1, listGroup2, listPrefectures } = useAppSelector(
    state => state.monitoring
  )

  const onClickGroup1 = () => {
    dispatch(FETCH_LIST_GROUP_2())
  }

  const onClickGroup2 = () => {
    dispatch(FETCH_LIST_PREFECTURES())
  }
  return (
    <Paper elevation={3} className="filter-wrapper">
      <Box onSubmit={handleSubmit(onSubmit)} component="form">
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Controller
                  name="group_1"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <InputLabel id="label_group_1">
                        {t('monitoring.filter.group_1')}
                      </InputLabel>
                      <Select
                        labelId="label_group_1"
                        multiple
                        {...field}
                        renderValue={(selected: number[]) =>
                          listGroup1
                            .filter(item => selected.includes(item.id))
                            .map(item => item.name)
                            .join(', ')
                        }
                        onClick={onClickGroup1}
                      >
                        {listGroup1.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            <Checkbox checked={field.value.includes(item.id)} />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="prefectures"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <InputLabel id="label_prefectures">
                        {t('monitoring.filter.prefectures')}
                      </InputLabel>
                      <Select
                        labelId="label_prefectures"
                        multiple
                        {...field}
                        renderValue={(selected: number[]) =>
                          listPrefectures
                            .filter(item => selected.includes(item.area_id))
                            .map(item => item.area_name)
                            .join(', ')
                        }
                        disabled={listPrefectures.length === 0}
                      >
                        {listPrefectures.map(item => (
                          <MenuItem key={item.area_id} value={item.area_id}>
                            <Checkbox
                              checked={field.value.includes(item.area_id)}
                            />
                            <ListItemText primary={item.area_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="box_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="box_name"
                      label={t('monitoring.filter.box_name')}
                      value={field.value.length}
                      inputProps={{ maxLength: 55 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="group_2"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <InputLabel id="label_group_2">
                        {t('monitoring.filter.group_2')}
                      </InputLabel>
                      <Select
                        labelId="label_group_2"
                        multiple
                        {...field}
                        renderValue={selected =>
                          listGroup2
                            .filter(item => selected.includes(item.id))
                            .map(item => item.name)
                            .join(', ')
                        }
                        onClick={onClickGroup2}
                        disabled={listGroup2.length === 0}
                      >
                        {listGroup2.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            <Checkbox checked={field.value.includes(item.id)} />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <SelectCommon
                      api="monitor_box_status"
                      label={t('monitoring.filter.status')}
                      handleChange={field.onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Stack direction="row" spacing={3} width="100%">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Controller
                      name="from_date"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          value={field.value}
                          onChange={e => {
                            field.onChange(e)
                            setStartTime(e)
                          }}
                          label={t('monitoring_history.from_date')}
                          maxDate={endTime !== null ? endTime : new Date()}
                          renderInput={params => (
                            <TextField {...params} error={false} />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <Box display="flex" alignItems="center">
                    ~
                  </Box>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Controller
                      name="to_date"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          value={field.value}
                          onChange={e => {
                            field.onChange(e)
                            setEndTime(e)
                          }}
                          label={t('monitoring_history.to_date')}
                          minDate={startTime}
                          maxDate={new Date()}
                          renderInput={params => (
                            <TextField {...params} error={false} />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button variant="contained" className="btn-search" type="submit">
              {t('monitoring.button.search')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default React.memo(FormFilter)
