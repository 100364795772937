import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IMonitoringState,
  IMonitoringHistoryVideoResponse,
  IMonitoringHistoryParams,
  IMonitoringHistoryCommentResponse,
} from '@/interfaces'

const initialState: IMonitoringState = {
  listHistoryVideo: [],
  metaHistoryVideo: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: '',
    last_page_url: '',
    next_page_url: '',
    previous_page_url: '',
  },
  listHistoryComment: [],
  metaHistoryComment: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: '',
    last_page_url: '',
    next_page_url: '',
    previous_page_url: '',
  },
  listBoxes: [],
  listGroup1: [],
  listGroup2: [],
  listPrefectures: [],
  listCommentPlayback: [],
  listCommentBoxDetail: [],
}

export const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    FETCH_HISTORY_VIDEO: (
      _,
      action: PayloadAction<IMonitoringHistoryParams>
    ) => {},
    SET_HISTORY_VIDEO: (
      state,
      action: PayloadAction<IMonitoringHistoryVideoResponse>
    ) => {
      state.listHistoryVideo = action.payload.data.map(item => ({
        ...item,
        key: item.id,
      }))
      state.metaHistoryVideo = action.payload.meta
    },
    FETCH_HISTORY_COMMENT: (
      _,
      action: PayloadAction<IMonitoringHistoryParams>
    ) => {},
    SET_HISTORY_COMMENT: (
      state,
      action: PayloadAction<IMonitoringHistoryCommentResponse>
    ) => {
      state.listHistoryComment = action.payload.data.map(item => ({
        ...item,
        key: item.id,
      }))
      state.metaHistoryComment = action.payload.meta
    },
    FETCH_LIST_BOXES: () =>
      // _,
      // TODO action: PayloadAction<IMonitoringHistoryParams>
      {},
    SET_LIST_BOXES: (
      state
      // TODO action: PayloadAction<IMonitoringHistoryParams>
    ) => {
      const listBoxes = [
        {
          id: 1,
          box_name: 'BX301',
          is_prepare: false,
          is_reservation: false,
          is_repair: false,
          start_time: '13:00',
          end_time: '15:00',
        },
        {
          id: 2,
          box_name: 'BX302',
          is_prepare: false,
          is_reservation: false,
          is_repair: false,
          start_time: '13:00',
          end_time: '15:00',
        },
        {
          id: 3,
          box_name: 'BX303',
          is_prepare: false,
          is_reservation: false,
          is_repair: false,
          start_time: '13:00',
          end_time: '15:00',
        },
        {
          id: 4,
          box_name: 'BX304',
          is_prepare: true,
          is_reservation: true,
          is_repair: false,
          start_time: '13:00',
          end_time: '15:00',
        },
        {
          id: 5,
          box_name: 'BX305',
          is_prepare: true,
          is_reservation: false,
          is_repair: false,
          start_time: '',
          end_time: '',
        },
        {
          id: 6,
          box_name: 'BX306',
          is_prepare: false,
          is_reservation: false,
          is_repair: true,
          start_time: '',
          end_time: '',
        },
      ]
      state.listBoxes = listBoxes
    },
    FETCH_LIST_GROUP_1: () =>
      // _,
      // TODO action: PayloadAction<IMonitoringHistoryParams>
      {},
    SET_LIST_GROUP_1: (
      state
      // TODO action: PayloadAction<IMonitoringHistoryParams>
    ) => {
      const listGroup = [
        {
          id: 1,
          name: 'group 1.1',
          parent_id: null,
          deleted_at: null,
          created_at: null,
          updated_at: null,
        },
        {
          id: 2,
          name: 'group 1.2',
          parent_id: null,
          deleted_at: null,
          created_at: null,
          updated_at: null,
        },
        {
          id: 3,
          name: 'group 1.3',
          parent_id: null,
          deleted_at: null,
          created_at: null,
          updated_at: null,
        },
      ]
      state.listGroup1 = listGroup
    },
    FETCH_LIST_GROUP_2: () =>
      // _,
      // TODO action: PayloadAction<IMonitoringHistoryParams>
      {},
    SET_LIST_GROUP_2: (
      state
      // TODO action: PayloadAction<IMonitoringHistoryParams>
    ) => {
      const listGroup = [
        {
          id: 1,
          name: 'group 2.1',
          parent_id: 1,
          deleted_at: null,
          created_at: null,
          updated_at: null,
        },
        {
          id: 2,
          name: 'group 2.2',
          parent_id: 1,
          deleted_at: null,
          created_at: null,
          updated_at: null,
        },
        {
          id: 3,
          name: 'group 2.3',
          parent_id: 1,
          deleted_at: null,
          created_at: null,
          updated_at: null,
        },
      ]
      state.listGroup2 = listGroup
    },
    FETCH_LIST_PREFECTURES: () =>
      // _,
      // TODO action: PayloadAction<IMonitoringHistoryParams>
      {},
    SET_LIST_PREFECTURES: (
      state
      // TODO action: PayloadAction<IMonitoringHistoryParams>
    ) => {
      const listPrefectures = [
        {
          area_id: 1,
          area_name: 'Area 1',
        },
        {
          area_id: 2,
          area_name: 'Area 2',
        },
        {
          area_id: 3,
          area_name: 'Area 3',
        },
        {
          area_id: 4,
          area_name: 'Area 4',
        },
      ]
      state.listPrefectures = listPrefectures
    },
    FETCH_COMMENT_PLAYBACK: () => {},
    SET_COMMENT_PLAYBACK: (
      state
      // action: PayloadAction<IMonitoringHistoryParams>
    ) => {
      const listCommentPlayback = [
        {
          id: 1,
          date: '2022/02/28 15:30',
          name_monitor: '高橋',
          content: '消灯→電力会社に連絡しました',
        },
        {
          id: 2,
          date: '2022/03/22 15:30',
          name_monitor: '高橋',
          content: '消灯→電力会社に連絡しました',
        },
        {
          id: 3,
          date: '2022/02/28 15:30',
          name_monitor: '高橋',
          content: '消灯→電力会社に連絡しました',
        },
        {
          id: 4,
          date: '2022/02/28 15:30',
          name_monitor: '高橋',
          content: '消灯→電力会社に連絡しました',
        },
        {
          id: 5,
          date: '2022/02/28 15:30',
          name_monitor: '高橋',
          content: '消灯→電力会社に連絡しました',
        },
      ]
      state.listCommentPlayback = listCommentPlayback
    },
    FETCH_COMMENT_BOX_DETAILS: () => {},
    SET_COMMENT_BOX_DETAILS: (
      state
      // action: PayloadAction<IMonitoringHistoryParams>
    ) => {
      const listCommentBoxDetail = [
        {
          id: 1,
          date: '2022/02/28 15:30',
          name_monitor: '高橋',
          content: '消灯→電力会社に連絡しました',
        },
        {
          id: 2,
          date: '2022/03/22 15:30',
          name_monitor: '高橋',
          content: '消灯→電力会社に連絡しました',
        },
        {
          id: 3,
          date: '2022/02/28 15:30',
          name_monitor: '高橋',
          content: '消灯→電力会社に連絡しました',
        },
        {
          id: 4,
          date: '2022/02/28 15:30',
          name_monitor: '高橋',
          content: '消灯→電力会社に連絡しました',
        },
        {
          id: 5,
          date: '2022/02/28 15:30',
          name_monitor: '高橋',
          content: '消灯→電力会社に連絡しました',
        },
      ]
      state.listCommentBoxDetail = listCommentBoxDetail
    },
  },
})

// Actions
export const {
  FETCH_HISTORY_VIDEO,
  SET_HISTORY_VIDEO,
  FETCH_HISTORY_COMMENT,
  SET_HISTORY_COMMENT,
  FETCH_LIST_BOXES,
  SET_LIST_BOXES,
  FETCH_LIST_GROUP_1,
  SET_LIST_GROUP_1,
  FETCH_LIST_GROUP_2,
  SET_LIST_GROUP_2,
  FETCH_LIST_PREFECTURES,
  SET_LIST_PREFECTURES,
  FETCH_COMMENT_PLAYBACK,
  SET_COMMENT_PLAYBACK,
  FETCH_COMMENT_BOX_DETAILS,
  SET_COMMENT_BOX_DETAILS,
} = monitoringSlice.actions

// Reducer
const monitoringReducer = monitoringSlice.reducer

export default monitoringReducer
