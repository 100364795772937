import React, { useEffect } from 'react'
import {
  getUserToken,
  getSessionToken,
  removeSessionToken,
  removeUserToken,
} from '@/utils/helper'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { Navigate, useLocation } from 'react-router-dom'
import PageLoading from '@components/common/PageLoading'
import { AUTH_GET_INFO } from '@/redux/reducers/users/auth.slice'
import { ROUTER_PATH } from '@/constants'

function PrivateRoute({ children }: { children: React.ReactElement }) {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const hasRerender = (prevState: any, state: any) => !state.loading
  const { currentUser, loading } = useAppSelector(
    state => state.auth,
    hasRerender
  )

  const clearToken = () => {
    removeSessionToken()
    removeUserToken()
  }

  let token: string | null

  token = getSessionToken()

  if (!token) {
    token = getUserToken()
  }

  useEffect(() => {
    if (loading) {
      dispatch(AUTH_GET_INFO())
    }
  }, [loading])

  if (!token) {
    const newPathName = `/sign-in?redirect=${encodeURIComponent(pathname)}`
    return <Navigate to={newPathName} />
  }

  if (loading) {
    return <PageLoading />
  }

  const userHasRequiredRole = currentUser && currentUser.id

  if (userHasRequiredRole) {
    return children
  }

  clearToken()
  return <Navigate to={ROUTER_PATH.AUTH.SIGN_IN} />
}

export default React.memo(PrivateRoute)
