import React from 'react';
import { Link as RouterLink, LinkProps as ReactLinkProps, useMatch, useResolvedPath } from 'react-router-dom';
import MuiLink from '@mui/material/Link';

interface LinkProps extends ReactLinkProps {
  activeClass?: string,
}

function Link({ children, to, activeClass }: LinkProps) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  const activeClassDefault = activeClass ?? 'link-active'
  return (
    <MuiLink className={ match ? activeClassDefault : 'link-inactive' } component={RouterLink} to={to}>
      {children}
    </MuiLink>
  )
}

Link.defaultProps = {
  activeClass: ''
};

export default Link