import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { FormType, IGroup, IManager, IRole } from '@/interfaces'
import UploadImage from '@components/common/UploadImage'
import { DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useParams, useNavigate, generatePath } from 'react-router-dom'
import { ROUTER_PATH } from '@/constants/router'
import {
  Grid,
  Box,
  Paper,
  TextField,
  Typography,
  Button,
  Stack,
  Container,
  Divider,
  MenuItem,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import {
  FETCH_LOWER_ROLES,
  FETCH_MONITOR_DETAIL,
  FETCH_MANAGERS,
  FETCH_GROUP_1,
  FETCH_GROUP_2,
  CREATE_MONITOR,
  EDIT_MONITOR,
  DELETE_MONITOR,
} from '@/redux/reducers/monitor.slice'
import GroupSelect from '@/components/Monitor/GroupSelect'
import SelectCommon from '@/components/common/SelectCommon'
import { DEFAULT_LANGUAGE, MESSAGE_CONTENT } from '@/constants'
import useDebounce from '@/hooks/useDebounce'
import dayjs from 'dayjs'
import { DUMMY_MONITOR } from '@/constants/monitor'
import ConfirmDialog from '@/components/common/ConfirmDialog'

function MonitorDetail() {
  const params = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { lowerRole, managers } = useAppSelector(state => state.monitor)
  const { languageMaster, isLoading, message } = useAppSelector(
    state => state.app
  )
  const [formType, setFormType] = useState<FormType>('view')
  const { monitorDetail } = useAppSelector(state => state.monitor)
  const [toggleDialog, setToggleDialog] = React.useState(false)
  const [controlAction, setControlAction] = useState<{
    lowerRole: IRole[]
    role: number | string
    managers: IManager[]
    manager: any
    group_1: number[]
    group_2: IGroup[]
  }>({
    lowerRole: [],
    role: '',
    managers: [],
    manager: '',
    group_1: [],
    group_2: [],
  })
  const debounceGroup1 = useDebounce<any>(controlAction.group_1, 900)

  const handleOpenDialog = () => {
    setToggleDialog(() => !toggleDialog)
  }
  const handleToggleDialog = () => {
    setToggleDialog(() => !toggleDialog)
  }
  const handleDelete = () => {
    dispatch(DELETE_MONITOR(params.id))
    if (!isLoading && message.type !== 'error')
      navigate(ROUTER_PATH.MONITOR_MANAGEMENT)
  }

  const MonitorSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t('validation.username_required'))
      .max(25, t('auth.validation.username_valid')),
    birthday: Yup.string().trim().required(t('validation.birthday_required')),
    sex: Yup.string().trim().required(t('validation.sex_required')),
    address: Yup.string()
      .trim()
      .max(255, t('validation.address_max'))
      .required(t('validation.address_required')),
    tel: Yup.string()
      .trim()
      .max(25, t('validation.phone_number_max'))
      .required(t('validation.phone_number_required'))
      .matches(/^[0-9]*$/, t('validation.phone_number_format')),
    email: Yup.string()
      .trim()
      .email(t('validation.email_invalid'))
      .max(100, t('validation.email_valid_max'))
      .required(t('validation.email_required')),
    role: Yup.string().required('Required!'),
    memo: Yup.string().trim().max(500, t('validation.description_invalid')),
  })
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(MonitorSchema),
    defaultValues: monitorDetail,
  })
  const handleNavigate = () => {
    if (formType === 'view') {
      navigate(
        generatePath(ROUTER_PATH.MONITOR_ACTION, {
          id: params.id,
          action: 'edit',
        })
      )
    } else if (formType === 'edit') {
      navigate(
        generatePath(ROUTER_PATH.MONITOR, {
          id: params.id,
        })
      )
    } else {
      navigate(ROUTER_PATH.MONITOR_MANAGEMENT)
    }
  }
  const onSubmit = (data: any) => {
    const formData = {
      name: data.name,
      birthday: data.birthday,
      sex: parseInt(data.sex, 10),
      address: data.address,
      tel: data.tel,
      email: data.email,
      role: parseInt(data.role, 10),
      parent_id: parseInt(data.parent_id, 10),
      memo: data.memo,
      group_boxes: data.group_boxes,
      upload_id: parseInt(data.upload_id, 10),
    }
    if (formType === 'create') {
      dispatch(CREATE_MONITOR(formData))
    } else {
      dispatch(EDIT_MONITOR({ id: params.id, formData }))
    }
  }

  useEffect(() => {
    if (
      message.content === MESSAGE_CONTENT.CREATE_SUCCESS ||
      message.content === MESSAGE_CONTENT.EDIT_SUCCESS ||
      message.content === MESSAGE_CONTENT.DELETE_SUCCESS
    )
      handleNavigate()
  }, [message.content])

  const handleBox1Selected = (boxes1: any) => {
    setControlAction({
      ...controlAction,
      group_1: boxes1,
    })
  }

  useEffect(() => {
    if (formType !== 'view') {
      if (controlAction.group_1.length !== 0) {
        dispatch(
          FETCH_GROUP_2({
            managerId: controlAction.manager,
            boxes1: debounceGroup1,
          })
        )
      }
    }
  }, [debounceGroup1])
  useEffect(() => {
    setControlAction({
      ...controlAction,
      managers,
    })
  }, [managers])
  useEffect(() => {
    setControlAction({
      ...controlAction,
      lowerRole,
    })
  }, [lowerRole])

  useEffect(() => {
    if (formType === 'create') {
      reset({ ...DUMMY_MONITOR })
    } else {
      reset(monitorDetail)
    }
  }, [formType, monitorDetail])
  useEffect(() => {
    if (formType === 'edit') {
      if (monitorDetail.role) {
        dispatch(FETCH_MANAGERS(monitorDetail.role))
        setControlAction({
          ...controlAction,
          role: monitorDetail.role,
        })
      }
    }
  }, [formType, monitorDetail])

  useEffect(() => {
    if (monitorDetail.parent_id && managers.length !== 0)
      setControlAction({
        ...controlAction,
        manager: monitorDetail.parent_id,
      })
  }, [monitorDetail.parent_id, managers])

  useEffect(() => {
    if (params.id) {
      dispatch(FETCH_MONITOR_DETAIL(params.id))
    }
    dispatch(FETCH_LOWER_ROLES())
  }, [])
  useEffect(() => {
    if (params.action === 'edit') {
      setFormType('edit')
    } else if (params.id) setFormType('view')
    else setFormType('create')
  }, [params])

  return (
    <>
      <Typography mb={1} variant="h6" fontWeight="bold">
        {formType === 'view' && t('monitoring.monitor_detail.title_view')}
        {formType === 'create' && t('monitoring.monitor_detail.title_create')}
        {formType === 'edit' && t('monitoring.monitor_detail.title_edit')}
      </Typography>
      <Box component={Paper} pt={2} pb={1}>
        <Container fixed>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            {formType !== 'view' && (
              <Box sx={{ ml: 2, mb: 2 }}>
                {t('monitoring.monitor_detail.please_enter')}
              </Box>
            )}
            <Grid container spacing={10}>
              <Grid container item xs={7}>
                <Stack direction="column" spacing={2} width="100%">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        id="name"
                        disabled={formType === 'view'}
                        label={t('monitoring.monitor_detail.name')}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Stack direction="row" spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Controller
                        name="birthday"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <DatePicker
                            {...field}
                            disabled={formType === 'view'}
                            views={['year', 'month', 'day']}
                            label={t('monitoring.monitor_detail.birthday')}
                            maxDate={new Date()}
                            onChange={newValue => {
                              field.onChange(
                                dayjs(newValue).format('YYYY/MM/DD')
                              )
                            }}
                            renderInput={param => (
                              <TextField
                                {...param}
                                helperText={error?.message}
                              />
                            )}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <Controller
                      name="sex"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <SelectCommon
                          api="sex_type"
                          isDisable={formType === 'view'}
                          isRequire
                          label={t('monitoring.monitor_detail.sex')}
                          defaultValueMaster={field.value?.toString()}
                          handleChange={field.onChange}
                          errMessage={error?.message}
                        />
                      )}
                    />
                  </Stack>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        id="address"
                        disabled={formType === 'view'}
                        label={t('monitoring.monitor_detail.address')}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="tel"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        id="tel"
                        disabled={formType === 'view'}
                        label={t('monitoring.monitor_detail.phone_number')}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        id="email"
                        disabled={formType === 'view'}
                        label={t('monitoring.monitor_detail.email_address')}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label={t('monitoring.monitor_detail.role')}
                        {...field}
                        select
                        disabled={
                          formType !== 'view'
                            ? controlAction.lowerRole.length === 0
                            : true
                        }
                        onChange={e => {
                          field.onChange(e.target.value)
                          setControlAction({
                            ...controlAction,
                            role: e.target.value,
                          })
                          dispatch(FETCH_MANAGERS(e.target.value))
                        }}
                        defaultValue={field.value}
                      >
                        {lowerRole.map(role => (
                          <MenuItem key={role.id} value={Number(role.value)}>
                            {languageMaster === DEFAULT_LANGUAGE
                              ? role.title_jp
                              : role.title_en}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                  <Controller
                    name={formType === 'view' ? 'parent_name' : 'parent_id'}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        select
                        disabled={
                          formType !== 'view' ? controlAction.role === '' : true
                        }
                        label={t('monitoring.monitor_detail.parent_name')}
                        defaultValue={field.value}
                        error={!!error}
                        helperText={error?.message}
                        onChange={e => {
                          field.onChange(e.target.value)
                          setControlAction({
                            ...controlAction,
                            manager: e.target.value,
                          })
                          dispatch(FETCH_GROUP_1(e.target.value))
                        }}
                      >
                        {formType === 'view' ? (
                          <MenuItem key={field.value} value={field.value}>
                            {field.value}
                          </MenuItem>
                        ) : (
                          managers.map(manager => (
                            <MenuItem
                              key={manager.id}
                              value={manager.id ? manager.id : ''}
                            >
                              {manager.name}
                            </MenuItem>
                          ))
                        )}
                      </TextField>
                    )}
                  />

                  <Box>
                    <Stack direction="row" spacing={4}>
                      <Controller
                        name="group_boxes1"
                        control={control}
                        render={({ field }) => (
                          <GroupSelect
                            formType={formType}
                            idInput="group_boxes1"
                            selectLabel={t('monitor_management.group_1')}
                            isDisable={
                              formType !== 'view'
                                ? controlAction.manager === ''
                                : true
                            }
                            onChange={handleBox1Selected}
                            defaultValue={field.value}
                          />
                        )}
                      />
                      <Controller
                        name="group_boxes"
                        control={control}
                        render={({ field }) => (
                          <GroupSelect
                            formType={formType}
                            idInput="group_boxes2"
                            selectLabel={t('monitor_management.group_2')}
                            isDisable={
                              formType !== 'view'
                                ? controlAction.group_1.length === 0
                                : true
                            }
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Stack>
                  </Box>
                  {formType === 'view' && (
                    <Box
                      component={Paper}
                      elevation={1}
                      sx={{ height: 190, overflow: 'auto' }}
                    >
                      <Grid container spacing={4} p={1}>
                        <Grid item xs={6}>
                          {monitorDetail.group_boxes1?.map(item => (
                            <Stack
                              key={item.name}
                              direction="column"
                              spacing={0.5}
                            >
                              <span>{item.name}</span>
                              <Divider />
                            </Stack>
                          ))}
                        </Grid>
                        <Grid item xs={6}>
                          {monitorDetail.group_boxes.length > 0 &&
                            monitorDetail.group_boxes?.map(item => (
                              <Stack
                                key={item.name}
                                direction="column"
                                spacing={0.5}
                              >
                                <span>{item?.name}</span>
                                <Divider />
                              </Stack>
                            ))}
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={5}>
                <Stack direction="column" spacing={5} width="100%">
                  {formType !== 'view' && (
                    <Box sx={{ ml: 1 }}>
                      {t('monitoring.monitor_detail.please_select')}
                    </Box>
                  )}
                  <Stack direction="row" spacing={3} alignItems="start">
                    <Controller
                      name="upload_id"
                      control={control}
                      render={({ field }) => (
                        <UploadImage
                          idUpload="upload_id"
                          size={{ height: '178px', width: '255px' }}
                          isDelete={formType !== 'view'}
                          type="MONITOR_AVATAR"
                          formType={formType}
                          imageURL={
                            formType !== 'create' ? monitorDetail.avatar : ''
                          }
                          onChange={field.onChange}
                          maxSize={1.5}
                        />
                      )}
                    />
                  </Stack>
                  <Controller
                    name="memo"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        disabled={formType === 'view'}
                        id="memo"
                        multiline
                        minRows={12}
                        label={t('monitoring.monitor_detail.detail')}
                        error={!!error}
                        helperText={error?.message}
                        inputProps={{ maxLength: 500 }}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            {formType === 'view' && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={6}
                mt={2}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate(ROUTER_PATH.MONITOR_MANAGEMENT)}
                >
                  {t('monitoring.monitor_detail.btn_return')}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleNavigate}
                >
                  {t('monitoring.monitor_detail.btn_edit')}
                </Button>
              </Stack>
            )}
            {formType === 'create' && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={6}
                mt={2}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate(ROUTER_PATH.MONITOR_MANAGEMENT)}
                >
                  {t('monitoring.monitor_detail.btn_return')}
                </Button>
                <Button variant="contained" size="small" type="submit">
                  {t('monitoring.monitor_detail.btn_register')}
                </Button>
              </Stack>
            )}
            {formType === 'edit' && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={6}
                mt={2}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    navigate(
                      generatePath(ROUTER_PATH.MONITOR, {
                        id: params.id,
                      })
                    )
                  }
                >
                  {t('monitoring.monitor_detail.btn_return')}
                </Button>
                <Button variant="contained" size="small" type="submit">
                  {t('monitoring.monitor_detail.btn_register')}
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={handleOpenDialog}
                >
                  {t('monitoring.monitor_detail.btn_delete')}
                </Button>
              </Stack>
            )}
          </Box>
        </Container>
        <ConfirmDialog
          dialogTitle={t('monitoring.monitor_detail.delete_monitor')}
          dialogContent={t('monitoring.monitor_detail.delete_description')}
          openDialog={toggleDialog}
          setOpenDialog={handleToggleDialog}
          onConfirmDialog={handleDelete}
          okText={t('monitoring.monitor_detail.btn_delete_monitor')}
          cancelText={t('confirm.cancel')}
        />
      </Box>
    </>
  )
}

export default React.memo(MonitorDetail)
