import { Grid, Box, Paper, Switch } from '@mui/material'
import { NoPhotography } from '@mui/icons-material'
import * as React from 'react'
import { IListCamera } from '@/interfaces'

interface ICameraComponent {
  camera: Array<IListCamera>
  setCamera: (camera: Array<IListCamera>) => void
  setIsOpenComment: () => void
}

function CameraComponent({
  camera,
  setCamera,
  setIsOpenComment,
}: ICameraComponent) {
  const handleZoomInCamera = (indexCamera: number) => {
    const cameraClone = [...camera]
    cameraClone.forEach((item, index) => {
      item.statusCamera.isZoom = indexCamera === index
    })
    setIsOpenComment()
    setCamera(cameraClone)
  }

  const handleZoomOutCamera = (indexCamera: number) => {
    const cameraClone = [...camera]
    cameraClone[indexCamera].statusCamera.isZoom = false
    setIsOpenComment()
    setCamera(cameraClone)
  }

  const toggleCameraOn = (indexCamera: number) => {
    const cameraClone = [...camera]
    cameraClone[indexCamera].statusCamera.status = true
    setIsOpenComment()
    setCamera(cameraClone)
  }

  const toggleCameraOff = (indexCamera: number) => {
    const cameraClone = [...camera]
    cameraClone[indexCamera].statusCamera.status = false
    setIsOpenComment()
    setCamera(cameraClone)
  }

  return (
    <Grid container spacing={2}>
      {camera.map((item, index) => {
        if (!item.statusCamera.isZoom)
          return (
            <Grid item xs={4} key={item.camera_id}>
              <Paper elevation={0} className="box-service-wrapper">
                {item.statusCamera.status ? (
                  <>
                    <Box
                      className="box-service"
                      onClick={() => handleZoomInCamera(index)}
                    />
                    <Box className="btn-switch">
                      <Switch
                        className="box-service-switch"
                        checked={item.statusCamera.status}
                        onChange={() => toggleCameraOff(index)}
                      />
                    </Box>
                  </>
                ) : (
                  <Box className="box-service-no-camera">
                    <Box
                      className="icon-no-camera"
                      onClick={() => handleZoomInCamera(index)}
                    >
                      <NoPhotography />
                    </Box>
                    <Switch
                      className="box-service-switch"
                      checked={item.statusCamera.status}
                      onChange={() => toggleCameraOn(index)}
                    />
                  </Box>
                )}
              </Paper>
            </Grid>
          )
        return <Box key={item.camera_id} />
      })}
      {camera.map((item, index) => {
        if (item.statusCamera.isZoom)
          return (
            <Grid item xs={6} key={item.camera_id}>
              <Paper elevation={0} className="box-service-wrapper">
                {item.statusCamera.status ? (
                  <>
                    <Box
                      className="box-service"
                      onClick={() => handleZoomOutCamera(index)}
                    />
                    <Box className="btn-switch">
                      <Switch
                        className="box-service-switch"
                        checked={item.statusCamera.status}
                        onChange={() => toggleCameraOff(index)}
                      />
                    </Box>
                  </>
                ) : (
                  <Box className="box-service-no-camera">
                    <Box
                      className="icon-no-camera"
                      onClick={() => handleZoomOutCamera(index)}
                    >
                      <NoPhotography />
                    </Box>
                    <Switch
                      className="box-service-switch"
                      checked={item.statusCamera.status}
                      onChange={() => toggleCameraOn(index)}
                    />
                  </Box>
                )}
              </Paper>
            </Grid>
          )
        return <Box key={item.camera_id} />
      })}
    </Grid>
  )
}

export default React.memo(CameraComponent)
