import '@/assets/styles/layout/main.scss'
import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  CssBaseline,
  Stack,
  Button,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { generatePath, Outlet, useNavigate } from 'react-router-dom'
import Header from '@/layout/MainLayout/Header'
import Sidebar from '@/layout/MainLayout/Sidebar'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import PageLoading from '@components/common/PageLoading'
import { useTranslation } from 'react-i18next'
import CallingTag from '@/components/common/CallingTag'
import useSocket from '@/hooks/useSocket'
import { PICKUP_A_CALL, HANDLE_CALL } from '@/redux/reducers/callVideo.slice'
import { ROUTER_PATH, TYPE_MESSAGE } from '@/constants'
import { useSnackbar } from 'notistack'
import { generateKey, getEnv } from '@/utils'

const theme = createTheme({
  palette: {
    mode: 'light',
  },
  components: {},
})

const ROOM_NAME = getEnv('DEBUG_ROOM_NAME')

function MainLayout() {
  const { t } = useTranslation()

  const { enqueueSnackbar } = useSnackbar()

  const { toggleSidebar, isLoading, message } = useAppSelector(
    state => state.app
  )

  const { isHaveCall, isCalling } = useAppSelector(state => state.callVideo)

  const [openDialog, setOpenDialog] = useState(true)

  const [isRing, setIsRing] = useState(false)

  const [incomingCalls, setIncomingCalls] = useState<any[]>([])

  const { isConnected, socket } = useSocket()

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const handleOpenDialog = () => {
    setOpenDialog(true)
    setIsRing(false)
  }
  const drawerWidth = toggleSidebar ? 250 : 0

  const handleHidden = () => {
    setOpenDialog(false)
    setIsRing(true)
  }

  const haveListenEventCall = useMemo(
    () => incomingCalls.length > 0 || isCalling,
    [incomingCalls, isCalling]
  )

  useEffect(() => {
    if (message.type !== '') {
      if (message.content !== '')
        enqueueSnackbar(message.content, {
          variant: message.type,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        })
      else if (message.type === TYPE_MESSAGE.SUCCESS)
        enqueueSnackbar(t('message.default_success'), {
          variant: message.type,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        })
      else
        enqueueSnackbar('message.default_error', {
          variant: message.type,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        })
    }
  }, [message])

  useEffect(() => {
    // mounted
    if (isConnected && !isHaveCall) {
      socket.off('connect').on('connect', () => {})
      socket.off('refuse_staff').on('refuse_staff', () => {
        dispatch(HANDLE_CALL(false))
      })
      socket
        .off('mirror_incoming_call')
        .on('mirror_incoming_call', (data: any) => {
          setIncomingCalls(prevState => [...prevState, data])
        })
    }

    return () => {
      if (socket) {
        socket.off('customize_course')
      }
    }
  }, [isConnected, isHaveCall])

  useEffect(() => {
    if (!socket) return
    if (!haveListenEventCall) {
      socket.off('customize_course')
      return
    }
    socket.on('customize_course', (data: any) => {
      const params = JSON.parse(data)
      if (params.eventInformation[0].eventButtonId === 'hangup') {
        setIncomingCalls(prevState =>
          prevState.filter(state => state.mirrorCode !== params.mirrorCode)
        )
      } else {
        if (Number(params.mirrorCode) !== Number(ROOM_NAME)) return
        if (
          isCalling &&
          params.eventInformation[0].eventButtonId === 'answer'
        ) {
          setIncomingCalls([])
          dispatch(HANDLE_CALL(false))
          dispatch(PICKUP_A_CALL(`monitor_${params.mirrorCode}`))
          navigate(
            generatePath(ROUTER_PATH.MONITORING_DETAILS, {
              id: '1',
            })
          )
        }
      }
    })
  }, [haveListenEventCall])

  useEffect(() => {
    if (!socket) return
    if (incomingCalls.length === 0 && isHaveCall) {
      socket.off('mirror_incoming_call')
      socket.off('monitor_accepted')
      return
    }
    socket.off('monitor_accepted').on('monitor_accepted', (data: any) => {
      // get incomingCalls
      setIncomingCalls(prevState =>
        prevState.filter(state => state.mirrorCode !== data.mirrorCode)
      )
    })
  }, [incomingCalls])

  const acceptIncomingCall = (data: any) => {
    setIncomingCalls([])
    dispatch(PICKUP_A_CALL(`monitor_${data.mirrorCode}`))
    dispatch(HANDLE_CALL(false))
    navigate(
      generatePath(ROUTER_PATH.MONITORING_DETAILS, {
        id: '1',
      })
    )
    data.eventInformation[0].eventButtonId = 'answer'
    socket.emit('monitor_accepted', data)
    socket.emit('customize_course', data)
  }

  const rejectIncomingCall = (data: any) => {
    setIncomingCalls(prevState =>
      prevState.filter(state => state.mirrorCode !== data.mirrorCode)
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Box className="layout-container">
        <CssBaseline />
        {isLoading && <PageLoading />}
        <Header />
        <Box className="layout-wrapper">
          <Sidebar width={drawerWidth} />
          <Box
            component="main"
            className="main-layout"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              position: 'relative',
            }}
          >
            <Dialog
              open={incomingCalls.length > 0 && openDialog}
              onClose={handleHidden}
              scroll="paper"
              PaperProps={{
                sx: {
                  overflow: 'hidden',
                  maxWidth: 450,
                  maxHeight: 350,
                  position: 'absolute',
                  bottom: 20,
                  right: 10,
                  zIndex: 1,
                },
              }}
            >
              <DialogContent dividers>
                {incomingCalls.map((item, index) => (
                  <CallingTag
                    key={generateKey('call_tag', index)}
                    callDetail={item}
                    acceptIncomingCall={() => acceptIncomingCall(item)}
                    setIncomingCalls={setIncomingCalls}
                    incomingCalls={incomingCalls}
                    rejectIncomingCall={() => rejectIncomingCall(item)}
                  />
                ))}
              </DialogContent>
              <Stack
                justifyContent="flex-end"
                direction="row"
                alignItems="center"
              >
                <Button
                  variant="text"
                  endIcon={<KeyboardArrowDownIcon fontSize="large" />}
                  sx={{ mr: 3 }}
                  onClick={handleHidden}
                >
                  <Typography variant="body2">
                    {t('common.hide_phone')}
                  </Typography>
                </Button>
              </Stack>
            </Dialog>
            <Outlet />
            {incomingCalls.length > 0 && isRing && (
              <IconButton
                className="phone-container"
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  zIndex: 1,
                }}
                onClick={handleOpenDialog}
              >
                <PhoneInTalkIcon
                  className="phone-ring"
                  sx={{
                    position: 'absolute',
                    bottom: 20,
                    right: 20,
                    zIndex: 1,
                    fontSize: 60,
                  }}
                />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default React.memo(MainLayout)
