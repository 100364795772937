import { useEffect, useState } from 'react'
import FlashPhoner, {
  constants,
} from '@flashphoner/websdk/src/flashphoner-core'
import Room, { events as ROOM_EVENT } from '@flashphoner/websdk/src/room-module'
import { getEnv } from '@/utils'
import { LEAVE_CALL } from '@/redux/reducers/callVideo.slice'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'

const WCS_HOST = getEnv('WCS_URL')
const { SESSION_STATUS } = constants

export default function useFlashPhonerRoom(roomName: string) {
  const [sessionStatus, setSessionStatus] = useState('')
  const [roomConnection, setRoomConnection] = useState<any>(null)
  const [currentRoom, setCurrentRoom] = useState<any>(null)
  const [participants, setParticipants] = useState<any[]>([])
  const dispatch = useAppDispatch()
  const { currentUser } = useAppSelector(state => state.auth)
  const onRoomEventState = async (room: any) => {
    // console.log('onRoomEventState')
    setCurrentRoom(room)
    let newParticipants = room.getParticipants().map((participant: any) => {
      const streams = participant.getStreams()
      const stream = streams[0] ?? null
      return {
        name: participant.name(),
        stream,
      }
    })
    newParticipants = newParticipants.filter((participant: any) => participant.stream !== null)
    setParticipants(newParticipants)
  }

  // const onParticipantJoin = useCallback((participant: any) => {
  //   console.log('Old participants', participants)
  //   const newParticipants = [...participants, participant]
  //   setParticipants(newParticipants)
  // }, [participants, roomConnection])

  useEffect(() => {
    // Mounted
    if (!roomName) return
    FlashPhoner.init({})
    const connection = Room.connect({
      urlServer: WCS_HOST,
      username: `Mentor ${currentUser.name}`,
    })
      .on(SESSION_STATUS.DISCONNECTED, (session: { status: () => any }) => {
        setSessionStatus(session.status())
      })
      .on(SESSION_STATUS.ESTABLISHED, (session: { status: () => any }) => {
        setSessionStatus(session.status())
        setRoomConnection(connection)
      })
  }, [roomName])

  useEffect(() => {
    if (!roomConnection) return
    roomConnection
      .join({
        name: roomName,
        record: false,
      })
      .on(ROOM_EVENT.STATE, (room: any) => {
        onRoomEventState(room)
      })
      .on(ROOM_EVENT.JOINED, (participant: any) => {
        // eslint-disable-next-line no-console
        console.log('New participant JOINED', participant.name())
      })
      .on(ROOM_EVENT.LEFT, (participant: any) => {
        // eslint-disable-next-line no-console
        console.log('New participant LEFT', participant.name())
        dispatch(LEAVE_CALL())
      })
      .on(ROOM_EVENT.PUBLISHED, (participant: any) => {
        setParticipants(prevState => [
          ...prevState,
          {
            name: participant.name(),
            stream: participant.getStreams()[0],
          },
        ])
      })
  }, [roomConnection])

  /**
   * TODO DEBUG
   */
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('useFF mounted')
    return () => {
      // eslint-disable-next-line no-console
      console.log('useFF unmounted')
      currentRoom?.leave()
    }
  }, [currentRoom])

  return {
    sessionStatus,
    roomConnection,
    participants,
    currentRoom,
  }
}
