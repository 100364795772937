import '@/assets/styles/pages/historyDetail.scss'
import * as React from 'react'
import { Box, Grid, Stack, Paper, Typography, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import posterVideo from '@/assets/images/background-box-service.png'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { FETCH_COMMENT_PLAYBACK } from '@/redux/reducers/monitoring.slice'

const dataVideo = [
  {
    id: 1,
    src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
  },
  {
    id: 2,
    src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
  },
  {
    id: 3,
    src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
  },
  {
    id: 3,
    src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
  },
]

function HistoryDetail() {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const { listCommentPlayback } = useAppSelector(state => state.monitoring)

  const [fullScreen, setFullScreen] = React.useState({
    isFullScreen: false,
    srcVideo: '',
  })

  const onClickFullScreen = (data: any) => {
    setFullScreen({
      isFullScreen: true,
      srcVideo: data,
    })
  }

  React.useEffect(() => {
    dispatch(FETCH_COMMENT_PLAYBACK())
  }, [])

  return (
    <Box p={1} sx={{ height: '100%' }}>
      <Typography mb={2} variant="h6" fontWeight="bold">
        {t('history.monitoring_history')}
      </Typography>
      <Box className="history-details-container">
        <Stack direction="row" alignItems="center" spacing={3} mb={3}>
          <Typography className="box-details-title">BX301</Typography>
          <Typography className="box-details-using">
            ユーザIDが利用中
          </Typography>
          <Typography className="box-details-using">客様：ユーザID</Typography>
          <Typography className="box-details-using">担当者：高橋</Typography>
        </Stack>
        <Container>
          <Grid container>
            <Grid item xs={fullScreen.isFullScreen ? 6 : 12}>
              <Stack direction="row" spacing={2} className="container-video">
                {dataVideo.map(item => (
                  <Paper
                    elevation={0}
                    className="box-service-wrapper"
                    sx={{
                      minWidth: fullScreen.isFullScreen ? 275 : 370,
                      height: fullScreen.isFullScreen ? 275 : 370,
                    }}
                    key={item.id}
                  >
                    <Box
                      className="box-service"
                      onClick={() => onClickFullScreen(item.src)}
                    >
                      <video
                        width="100%"
                        height="100%"
                        controls
                        poster={posterVideo}
                      >
                        <source src={item.src} type="video/mp4" />
                        <track kind="captions" />
                      </video>
                    </Box>
                  </Paper>
                ))}
              </Stack>
              <Paper className="comment-container">
                <Stack spacing={3} sx={{ mt: 5, p: 3 }}>
                  <Box
                    className="comment-content"
                    px={3}
                    pb={3}
                    sx={{ maxHeight: fullScreen.isFullScreen ? 275 : 370 }}
                  >
                    {listCommentPlayback.map(item => (
                      <Stack mt={1}>
                        <Stack direction="row" key={item.id}>
                          <Typography fontWeight="bold">{item.date}</Typography>
                          <Typography color="primary" ml={2}>
                            {item.name_monitor}
                          </Typography>
                        </Stack>
                        <Typography>{item.content}</Typography>
                      </Stack>
                    ))}
                  </Box>
                </Stack>
              </Paper>
            </Grid>
            {fullScreen.isFullScreen && (
              <Grid item xs={6} pl={3}>
                <Paper
                  elevation={0}
                  className="box-service-wrapper box-service-wrapper-zoom"
                >
                  <Box
                    className="box-service"
                    onClick={() => onClickFullScreen(dataVideo[1].src)}
                  >
                    <video width="100%" height="100%" controls>
                      <source src={dataVideo[1].src} type="video/mp4" />
                      <track kind="captions" />
                    </video>
                  </Box>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}
export default React.memo(HistoryDetail)
