import { IconButton, Paper, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { CallRounded, CallEndRounded } from '@mui/icons-material'

function CallingTag({
  callDetail,
  acceptIncomingCall,
  rejectIncomingCall,
  setIncomingCalls,
}: any) {
  useEffect(() => {
    setTimeout(() => {
      setIncomingCalls((prev: any) =>
        prev.filter((item: any) => item.mirrorCode !== callDetail.mirrorCode)
      )
    }, 30000)
  }, [])

  return (
    <Paper
      sx={{
        borderRadius: 2,
        px: 2,
        my: 1,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={6}
      >
        <Stack direction="row" spacing={1}>
          <Typography>{callDetail.userId || 'ababab'}</Typography>
          <Typography>{callDetail.companyCode}</Typography>
          <Typography>Receiving</Typography>
        </Stack>
        <Stack direction="row">
          <IconButton color="primary" onClick={() => acceptIncomingCall()}>
            <CallRounded />
          </IconButton>
          <IconButton color="secondary" onClick={() => rejectIncomingCall()}>
            <CallEndRounded />
          </IconButton>
        </Stack>
      </Stack>
    </Paper>
  )
}

export default React.memo(CallingTag)
