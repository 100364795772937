import '@/assets/styles/layout/sidebar.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import * as React from 'react'
import {
  List,
  Stack,
  Typography,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from '@mui/material'
import { Dvr, House, Settings, Groups, Monitor } from '@mui/icons-material'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useTranslation } from 'react-i18next'
import { TOGGLE_SIDEBAR } from '@/redux/reducers/app.slice'
import { ROUTER_PATH } from '@/constants'

interface Props {
  width: number
}

function Sidebar(props: Props) {
  const { width } = props
  const { t } = useTranslation()
  const SIDEBAR_ITEMS = {
    MONITORING: [
      {
        id: 'm1',
        title: t('sidebar.dashboard'),
        path: ROUTER_PATH.DASHBOARD,
        icon: <House />,
      },
      {
        id: 'm2',
        title: t('sidebar.monitor_management'),
        path: ROUTER_PATH.MONITOR_MANAGEMENT,
        icon: <Dvr />,
      },
      {
        id: 'm3',
        title: t('sidebar.group_management'),
        path: ROUTER_PATH.GROUP_MANAGEMENT,
        icon: <Groups />,
      },
      {
        id: 'm4',
        title: t('sidebar.monitoring'),
        path: ROUTER_PATH.MONITORING,
        icon: <Monitor />,
      },
      {
        id: 'm5',
        title: t('sidebar.setting'),
        path: ROUTER_PATH.SETTING,
        icon: <Settings />,
      },
    ],
  }
  // const matchUpMd: boolean = useMediaQuery(theme.breakpoints.up('md'));
  // console.log('matchUpMd', matchUpMd)
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const toggleSidebar = useAppSelector(state => state.app.toggleSidebar)

  const handleMenuClick = (item: any) => {
    navigate(item.path)
    if (!toggleSidebar) dispatch(TOGGLE_SIDEBAR())
  }

  const renderSidebarItems = React.useMemo(
    () =>
      SIDEBAR_ITEMS.MONITORING.map(item => (
        <ListItemButton
          className="list-item"
          selected={item.path === `/${location.pathname.split('/')[1]}`}
          key={item.id}
          onClick={() => handleMenuClick(item)}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" sx={{ marginLeft: -2 }}>
                {item.title}
              </Typography>
            }
          />
        </ListItemButton>
      )),
    [SIDEBAR_ITEMS, toggleSidebar, location]
  )

  return (
    <Stack direction="row" className="sidebar-container">
      <Stack
        direction="column"
        className="left-wrapper"
        sx={{
          display: toggleSidebar ? 'block' : 'none',
          width: toggleSidebar ? width : 0,
          boxShadow: 2,
        }}
      >
        <List
          component="nav"
          className="sidebar-list"
          sx={{
            bgcolor: 'background.paper',
          }}
          aria-labelledby="nested-list-subheader"
        >
          {renderSidebarItems}
        </List>
      </Stack>
    </Stack>
  )
}

export default React.memo(Sidebar)
