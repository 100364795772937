import '@/assets/styles/pages/monitoring-history.scss'
import React, { useState } from 'react'
import {
  Box,
  Stack,
  Paper,
  Container,
  Typography,
  Tabs,
  Tab,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import TabVideo from '@components/Monitoring/History/TabVideo'
import TabComment from '@components/Monitoring/History/TabComment'
import TabPanel from '@components/common/TabPanel'

function Monitoring() {
  const [tab, setTab] = useState(0)
  const { t } = useTranslation()
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  return (
    <Box sx={{ height: '100%' }}>
      <Typography mb={2} variant="h6" fontWeight="bold">
        {t('monitoring_history.title')}
      </Typography>
      <Box className="monitoring-history-container">
        <Stack direction="row">
          <Typography className="box-details-title">BX301</Typography>
        </Stack>
        <Container
          fixed
          component={Paper}
          sx={{ p: 0, pl: '0 !important', pr: '0 !important' }}
        >
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label={t('monitoring_history.videoTab')} />
            <Tab label={t('monitoring_history.commentTab')} />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <TabVideo />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <TabComment />
          </TabPanel>
        </Container>
      </Box>
    </Box>
  )
}

export default React.memo(Monitoring)
