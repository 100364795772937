import React from 'react'
import { Navigate } from 'react-router-dom'
import { getUserToken, getSessionToken } from '@/utils'

function PublicRoute({ children }: { children: React.ReactElement }) {
  let token: string | null

  token = getSessionToken()

  if (!token) token = getUserToken()

  if (token) {
    return <Navigate to="/dashboard" />
  }

  return children
}

export default React.memo(PublicRoute)
