import React from 'react'
import { Stack, Dialog, DialogTitle, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ConfirmDialogProps } from '@/interfaces'

function ConfirmDialog({
  dialogTitle,
  dialogContent,
  openDialog,
  setOpenDialog,
  onConfirmDialog,
  okText,
  cancelText,
}: ConfirmDialogProps) {
  const { t } = useTranslation()

  return (
    <Dialog
      onClose={() => setOpenDialog(!openDialog)}
      open={openDialog}
      maxWidth="md"
      fullWidth
      sx={{
        padding: 3,
      }}
    >
      <Stack direction="column" spacing={8} alignItems="center" p={3}>
        {dialogTitle && dialogTitle.length > 0 && (
          <DialogTitle sx={{ padding: 0, fontWeight: 'bold', fontSize: 45 }}>
            {dialogTitle || t('header.button.logout')}
          </DialogTitle>
        )}
        <Typography textAlign="center" fontSize={20}>
          {dialogContent}
        </Typography>
        <Stack direction="row" spacing={4} width="80%">
          <Button
            variant="outlined"
            sx={{
              fontWeight: 600,
              fontSize: 14,
              height: 36,
            }}
            onClick={() => setOpenDialog(!openDialog)}
          >
            {cancelText || t('header.button.cancel')}
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="error"
            sx={{
              fontWeight: 600,
              fontSize: 14,
              height: 36,
            }}
            onClick={onConfirmDialog}
          >
            {okText || t('header.button.ok')}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default React.memo(ConfirmDialog)
