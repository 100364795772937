import React from 'react'
import MainLayout from '@/layout/MainLayout'
import { RouteObject } from 'react-router-dom'
import PrivateRoute from '@components/common/PrivateRoute'
import Dashboard from '@/pages/Dashboard'
import HistoryPlayVideo from '@/pages/Monitoring/HistoryPlayVideo'
import MonitoringManagement from '@/pages/MonitorManagement'
import Monitor from '@/pages/MonitorManagement/Monitor'
import GroupManagement from '@/pages/GroupManagement'
import Monitoring from '@/pages/Monitoring'
import MonitoringHistory from '@/pages/Monitoring/MonitoringHistory'
import BoxDetails from '@/pages/Monitoring/Detail'
import Setting from '@/pages/Setting'
import Profile from '@/pages/Profile'

const PrivateRouter: RouteObject = {
  path: '/',
  element: (
    <PrivateRoute>
      <MainLayout />
    </PrivateRoute>
  ),
  children: [
    {
      index: true,
      path: 'dashboard',
      element: <Dashboard />,
    },
    {
      path: 'monitor-management',
      element: <MonitoringManagement />,
    },
    {
      path: 'monitor-management/monitor/:id',
      element: <Monitor />
    },
    {
      path: 'monitor-management/monitor/:id/:action',
      element: <Monitor />,
    },
    {
      path: 'monitor-management/monitor/create',
      element: <Monitor />
    },
    {
      path: 'group-management',
      element: <GroupManagement />,
    },
    {
      path: 'monitoring',
      element: <Monitoring />,
    },
    {
      path: 'monitoring/history/:id',
      element: <MonitoringHistory />,
    },
    {
      path: 'monitoring/history/playback/:id',
      element: <HistoryPlayVideo />,
    },
    {
      path: 'monitoring/:id',
      element: <BoxDetails />,
    },
    {
      path: 'setting',
      element: <Setting />,
    },
    {
      path: 'profile/',
      element: <Profile />,
    },
    {
      path: 'profile/:action',
      element: <Profile />,
    },
  ],
}

export default PrivateRouter
