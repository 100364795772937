import '@/assets/styles/pages/Monitor/GroupSelect.scss'
import React, { useEffect, useState } from 'react'
import { IGroupSelectProps } from '@/interfaces'
import { useAppSelector } from '@/redux/hooks'
import {
  Checkbox,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material'
import { DUMMY_VALUE } from '@/constants'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

function GroupSelect({
  idInput,
  selectLabel,
  onChange,
  isDisable,
  defaultValue,
  formType,
}: IGroupSelectProps) {
  const { group1, group2 } = useAppSelector(state => state.monitor)
  const [selected, setSelected] = useState<any[]>([])
  const [group2Selected, setGroup2Selected] = useState<any[]>([])
  const [group2Touched, setGroup2Touch] = useState<boolean>(false)

  const isAllSelected = group1.length > 0 && selected.length === group1.length
  const isAllGroup2Selected =
    group2.length > 0 && group2Selected.length === group2.length
  const handleChange = (event: any) => {
    const { value } = event.target
    if (idInput === 'group_boxes1') {
      if (value[value.length - 1] === DUMMY_VALUE.ALL) {
        setSelected(
          selected.length === group1.length ? [] : group1.map(item => item.id)
        )
        if (onChange) {
          onChange(
            selected.length === group1.length ? [] : group1.map(item => item.id)
          )
        }
        return
      }
      setSelected(value)
      if (onChange) {
        onChange(value)
      }
    } else {
      if (value[value.length - 1] === DUMMY_VALUE.ALL) {
        setGroup2Selected(
          group2Selected.length === group2.length
            ? []
            : group2.map(option => option.id)
        )
        return
      }
      setGroup2Selected(value)
    }
  }
  const renderSelected = () => {
    const newSelected = group1.filter(item => selected.includes(item.id))
    return newSelected.map(item => item.name).join(', ')
  }

  const renderGroup2Selected = () => {
    const newGroup2Selected = group2.filter(item =>
      group2Selected.includes(item.id)
    )
    return newGroup2Selected.map(item => item.name).join(', ')
  }

  useEffect(() => {
    if (onChange) {
      onChange(group2.filter(item => group2Selected.includes(item.id)))
    }
  }, [group2Selected])

  useEffect(() => {
    if (formType === 'edit') {
      setSelected(group1.map(item => item.id))
      setGroup2Selected(group2.map(item => item.id))
    }
  }, [group1, formType])

  return (
    <FormControl className="form-control">
      <InputLabel id={idInput}>{selectLabel}</InputLabel>
      <Select
        labelId={idInput}
        multiple
        disabled={
          idInput === 'group_boxes' ? !group2Touched && isDisable : isDisable
        }
        onBlur={
          idInput === 'group_boxes1'
            ? () => setGroup2Touch(!group2Touched)
            : undefined
        }
        value={idInput === 'group_boxes1' ? selected : group2Selected}
        defaultValue={defaultValue}
        onChange={handleChange}
        renderValue={
          idInput === 'group_boxes1' ? renderSelected : renderGroup2Selected
        }
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'menu',
        }}
      >
        <MenuItem
          value={DUMMY_VALUE.ALL}
          classes={{
            root: isAllSelected ? 'selected-all' : '',
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: '#3399bd' }}
              checked={
                idInput === 'group_boxes1' ? isAllSelected : isAllGroup2Selected
              }
              indeterminate={
                idInput === 'group_boxes1'
                  ? selected.length > 0 && selected.length < group1.length
                  : group2Selected.length > 0 &&
                    group2Selected.length < group2.length
              }
            />
          </ListItemIcon>
          <ListItemText primary="Select All" />
        </MenuItem>
        {idInput === 'group_boxes1'
          ? group1?.map(option => (
              <MenuItem key={option.id} value={option.id}>
                <ListItemIcon>
                  <Checkbox
                    checked={
                      selected.findIndex(item => item === option.id) !== -1
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={option.name} />
              </MenuItem>
            ))
          : group2.map(option => (
              <MenuItem key={option.id} value={option.id}>
                <ListItemIcon>
                  <Checkbox
                    checked={
                      group2Selected.findIndex(item => item === option.id) !==
                      -1
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={option.name} />
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  )
}

export default React.memo(GroupSelect)
