import React, { useState, useEffect } from 'react'
import { Box, Avatar, Stack, IconButton, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { UploadImageProps } from '@/interfaces'
import { apiUpload } from '@/api/uploadImage'
import { useTranslation } from 'react-i18next'
import { handleMessageError } from '@/utils/helper'
import { useAppDispatch } from '@/redux/hooks'
import { SET_LOADING } from '@/redux/reducers/app.slice'

function UploadImage({
  type,
  size,
  imageURL,
  formType,
  children,
  onChange,
  idUpload,
  isDelete,
  errMessage,
  maxSize,
}: UploadImageProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [imageUpload, setImageUpload] = useState({
    isLoading: false,
    imageURL,
    err: false,
    uploadError: '',
  })
  const handleUpload = async (file: any) => {
    if (file) {
      setImageUpload({ ...imageUpload, isLoading: true, err: false })
      if (maxSize && file.size >= maxSize * 1024 ** 2) {
        setImageUpload({
          isLoading: false,
          imageURL: '',
          err: !imageUpload.err,
          uploadError: t('validation.avatar_invalid'),
        })
        return
      }
      const iconForm = new FormData()
      iconForm.append('type', type)
      iconForm.append('file', file)

      try {
        dispatch(SET_LOADING(true))
        const response = await apiUpload(iconForm)
        const { id } = response.data
        onChange(id)
        setImageUpload({
          ...imageUpload,
          imageURL: response.data.file_url,
          isLoading: false,
          err: false,
        })
      } catch (error) {
        setImageUpload({
          imageURL: '',
          isLoading: false,
          err: true,
          uploadError: handleMessageError(error),
        })
      } finally {
        dispatch(SET_LOADING(false))
      }
    }
  }
  useEffect(() => {
    setImageUpload({ ...imageUpload, imageURL })
  }, [imageURL])

  return (
    <label
      htmlFor={idUpload}
      style={{ width: size.width, height: size.height }}
    >
      <Stack direction="row" spacing={3} alignItems="center">
        <Avatar
          src={imageUpload.imageURL}
          alt="Avatar"
          variant="square"
          sx={{
            width: size.width,
            height: size.height,
            borderRadius: 2,
            cursor: formType === 'view' ? 'default' : 'pointer',
            objectFit: 'cover',
          }}
        >
          {children}
        </Avatar>
        <input
          disabled={formType === 'view'}
          style={{ display: 'none' }}
          id={idUpload}
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          onChange={e => {
            if (e.target.files) {
              handleUpload(e.target.files[0])
            }
          }}
        />
        {isDelete && (
          <Box>
            <IconButton
              disabled={type === 'view'}
              onClick={() => {
                onChange(undefined)
                setImageUpload({ ...imageUpload, imageURL: '' })
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        )}
      </Stack>
      {imageUpload.err ? (
        <Typography variant="body2" color="error">
          {errMessage || imageUpload.uploadError}
        </Typography>
      ) : (
        <> </>
      )}
    </label>
  )
}

export default React.memo(UploadImage)
