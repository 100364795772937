import {
  Grid,
  Stack,
  Box,
  InputBase,
  IconButton,
  Typography,
} from '@mui/material'
import { Send } from '@mui/icons-material'
import ImageComment from '@/assets/images/image_comment.png'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@/redux/hooks'

export interface ICommentBoxProps {
  commentContent: string
  setCommentContent: (data: string) => void
}

function CommentBox({ commentContent, setCommentContent }: ICommentBoxProps) {
  const { t } = useTranslation()

  const { listCommentBoxDetail } = useAppSelector(state => state.monitoring)

  const addComment = () => {
    setCommentContent('')
  }

  return (
    <Box className="talk-bubble tri-right left-top">
      <Grid container height="100%" spacing={3}>
        <Grid item xs={9}>
          <Box className="comment-container" sx={{ height: '100%' }}>
            <Stack
              spacing={3}
              sx={{ p: 3, pb: 0 }}
              height="100%"
              justifyContent="space-between"
            >
              {listCommentBoxDetail.length > 0 && (
                <Box className="comment-content" px={3} pb={3}>
                  {listCommentBoxDetail.map(item => (
                    <Stack mt={1}>
                      <Stack direction="row" key={item.id}>
                        <Typography fontWeight="bold">{item.date}</Typography>
                        <Typography color="primary" ml={2}>
                          ({item.name_monitor})
                        </Typography>
                      </Stack>
                      <Typography>{item.content}</Typography>
                    </Stack>
                  ))}
                </Box>
              )}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems={
                  listCommentBoxDetail.length === 0 ? 'flex-start' : 'center'
                }
                height={listCommentBoxDetail.length === 0 ? '100%' : 'unset'}
                className="comment-send"
              >
                <InputBase
                  value={commentContent}
                  sx={{ ml: 1 }}
                  placeholder={t('history.comment')}
                  onChange={e => setCommentContent(e.target.value)}
                  fullWidth
                  inputProps={{ maxLength: 500 }}
                />
                <IconButton
                  type="submit"
                  className="icon-send"
                  onClick={addComment}
                  disabled={commentContent.trim() === ''}
                  sx={{
                    opacity: commentContent.trim() === '' ? 0.5 : 1,
                  }}
                >
                  <Send />
                </IconButton>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={3} display="flex" alignItems="flex-end" pr={3}>
          <Box
            component="img"
            src={ImageComment}
            alt="image comment"
            height="70%"
            width="100%"
            sx={{ objectFit: 'contain' }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(CommentBox)
