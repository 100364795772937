export const ROUTER_PATH = {
  AUTH: {
    SIGN_IN: '/sign-in',
    SIGN_UP: '/sign-up',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
  },
  DASHBOARD: '/dashboard',
  MONITOR_MANAGEMENT: '/monitor-management',
  GROUP_MANAGEMENT: '/group-management',
  MONITORING: '/monitoring',
  MONITORING_HISTORY: '/monitoring/history/:id',
  MONITORING_DETAILS: '/monitoring/:id',
  SETTING: '/setting',
  NOTIFICATIONS: '/notifications',
  MONITOR: '/monitor-management/monitor/:id',
  MONITOR_ACTION: '/monitor-management/monitor/:id/:action',
  MONITOR_CREATE: '/monitor-management/monitor/create',
  PROFILE: '/profile',
  PROFILE_EDIT: '/profile/:action',
  POLICY: '/policy',
}
