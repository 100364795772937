import {
  IMonitorState,
  IMonitor,
  IRole,
  IGroup,
  ICreateMonitor,
} from '@/interfaces'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DUMMY_MONITOR } from '@/constants/monitor'

const initialState: IMonitorState = {
  monitorDetail: DUMMY_MONITOR,

  isLoading: {
    getDetail: false,
    editDetail: false,
    createMonitor: false,
  },

  lowerRole: [],
  managers: [],
  group1: [] as any[],
  group2: [] as IGroup[],

  monitorList: {
    availableInfo: {
      group1: [],
      group2: [],
      role: [],
    },

    meta: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      first_page: '',
      first_page_url: '',
      last_page_url: '',
      next_page_url: '',
      previous_page_url: '',
    },
    data: [] as any[],
  },
}

export const monitorSlice = createSlice({
  name: 'monitor',
  initialState,
  reducers: {
    FETCH_MONITOR_DETAIL: (state, action: PayloadAction<string>) => {
      state.isLoading.getDetail = true
    },
    SET_MONITOR_DETAIL: (state, action: PayloadAction<IMonitor>) => {
      const data = {
        ...action.payload,
        group_boxes: action.payload.group_boxes1.flatMap(
          item => item.group_box2
        ),
      }
      state.monitorDetail = data
      state.group1 = data.group_boxes1
      state.group2 = data.group_boxes
      state.isLoading.getDetail = false
    },
    FETCH_LOWER_ROLES: () => {},
    SET_LOWER_ROLES: (state, action: PayloadAction<IRole[]>) => {
      state.lowerRole = action.payload
    },

    FETCH_MANAGERS: (state, action: PayloadAction<string | number>) => {},
    SET_MANAGERS: (state, action: PayloadAction<any>) => {
      state.managers = action.payload
    },

    FETCH_GROUP_1: (state, action: PayloadAction<string | number>) => {},
    SET_GROUP_1: (state, action: PayloadAction<any>) => {
      state.group1 = action.payload
    },

    FETCH_GROUP_2: (
      state,
      action: PayloadAction<{
        managerId: string | number | undefined
        boxes1: number[]
      }>
    ) => {},
    SET_GROUP_2: (state, action: PayloadAction<IGroup[]>) => {
      state.group2 = action.payload
    },

    CREATE_MONITOR: (state, action: PayloadAction<ICreateMonitor>) => {},

    EDIT_MONITOR: (state, action: PayloadAction<any>) => {},

    DELETE_MONITOR: (state, action: PayloadAction<string | undefined>) => {},

    FETCH_GROUP: () => {},

    SET_AVAILABLE_GROUP: (state, action: PayloadAction<any>) => {
      state.monitorList.availableInfo = action.payload
    },

    FETCH_MONITOR_LIST: (state, action: PayloadAction<any>) => {},

    SET_MONITOR_LIST: (state, action: PayloadAction<any>) => {
      state.monitorList = { ...state.monitorList, ...action.payload }
    },
  },
})

// Actions
export const {
  FETCH_MONITOR_DETAIL,
  SET_MONITOR_DETAIL,
  FETCH_LOWER_ROLES,
  SET_LOWER_ROLES,
  FETCH_MANAGERS,
  SET_MANAGERS,
  FETCH_GROUP_1,
  SET_GROUP_1,
  FETCH_GROUP_2,
  SET_GROUP_2,
  CREATE_MONITOR,
  FETCH_GROUP,
  SET_AVAILABLE_GROUP,
  EDIT_MONITOR,
  DELETE_MONITOR,
  FETCH_MONITOR_LIST,
  SET_MONITOR_LIST,
} = monitorSlice.actions

// Reducer
export default monitorSlice.reducer
