import { ProfileState, IProfile, IEditProfile, IResetPassword } from '@/interfaces'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: ProfileState = {
  profile: {
    id: undefined,
    email: '',
    tel: '',
    name: '',
    sex: '',
    birthday: '',
    address: '',
    memo: '',
    status: '',
    role: '',
    parent_id: '',
    login_failed: 0,
    deleted_at: '',
    created_at: '',
    updated_at: '',
    avatar: '',
    parent_name: '',
    group_box1: [
      {
        id: undefined,
        name: '',
        parent_id: null,
        deleted_at: null,
        created_at: '',
        updated_at: '',
        group_box2: [
          {
            id: undefined,
            name: '',
            parent_id: null,
            deleted_at: null,
            created_at: '',
            updated_at: '',
          },
        ],
      },
    ],
  },
  isLoading: {
    edit: false,
    view: false,
  },
}



export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    FETCH_PROFILE_DETAIL: state => {
      state.isLoading.view = true
    },
    SET_PROFILE_DETAIL: (state, action: PayloadAction<IProfile>) => {
      state.profile = action.payload
      state.isLoading.view = false
    },
    EDIT_PROFILE: (state, action: PayloadAction<IEditProfile>) => { },
    EDIT_RESET_PASSWORD_PROFILE: (state, action: PayloadAction<IResetPassword>) => { },
  },
})

// Actions
export const {
  FETCH_PROFILE_DETAIL,
  SET_PROFILE_DETAIL,
  EDIT_PROFILE,
  EDIT_RESET_PASSWORD_PROFILE
} = profileSlice.actions

// Reducer
export default profileSlice.reducer
