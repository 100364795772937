import { all, takeLatest, put, call, ForkEffect } from 'redux-saga/effects'
import {
  apiUserSignIn,
  apiUserRegister,
  apiUserSignOut,
  apiUserForgotPassword,
  apiUserResetPassword,
} from '@/api/auth'
import { apiGetMe } from '@/api/me'
import { AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  AuthRegister,
  AuthSignIn,
  AuthForgotPassword,
  AuthResetPassword,
} from '@/interfaces'
import {
  setUserToken,
  setSessionToken,
  removeUserToken,
  removeSessionToken,
} from '@/utils/helper'
import {
  AUTH_USER_LOGIN,
  AUTH_SET_CURRENT_USER,
  AUTH_USER_REGISTER,
  AUTH_USER_FORGOT_PASSWORD,
  AUTH_USER_RESET_PASSWORD,
  AUTH_USER_CHECK_TOKEN,
  AUTH_GET_INFO,
  AUTH_LOGOUT,
  AUTH_LOADING,
} from '@/redux/reducers/users/auth.slice'
import {
  SET_LOADING,
  RESET_MESSAGE,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { handleMessageError } from '@/utils'

function* signIn(action: PayloadAction<AuthSignIn>) {
  try {
    yield put(SET_LOADING(true))
    const { isRemember } = action.payload
    const response: AxiosResponse = yield apiUserSignIn(action.payload)
    const { token, monitor } = response.data
    if (isRemember) {
      setUserToken(token)
    }
    setSessionToken(token)
    yield put(AUTH_SET_CURRENT_USER(monitor))
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: 'sign_in_success',
      })
    )
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* register(action: PayloadAction<AuthRegister>) {
  try {
    yield put(SET_LOADING(true))
    const { payload } = action
    yield apiUserRegister(payload)
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: 'success',
      })
    )
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* forgotPassword(action: PayloadAction<AuthForgotPassword>) {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const { payload } = action
    yield apiUserForgotPassword(payload)
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: 'forgot_password_success',
      })
    )
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* resetPassword(action: PayloadAction<AuthResetPassword>) {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const { payload } = action
    yield apiUserResetPassword(payload)
    yield put(AUTH_USER_CHECK_TOKEN('success'))
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: 'reset_password_success',
      })
    )
  } catch (err: any) {
    if (err.response?.status === 410) {
      yield put(AUTH_USER_CHECK_TOKEN('time-out'))
    }
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getInfo() {
  yield put(AUTH_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    const response: AxiosResponse = yield call(apiGetMe)
    yield put(AUTH_SET_CURRENT_USER(response.data))
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: 'get_me_success',
      })
    )
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(AUTH_LOADING(false))
  }
}

function* logout() {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    yield call(apiUserSignOut)
    removeUserToken()
    removeSessionToken()
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

export default function* authSaga() {
  const filteredSagas: ForkEffect[] = [
    takeLatest(AUTH_USER_LOGIN, signIn),
    takeLatest(AUTH_USER_REGISTER, register),
    takeLatest(AUTH_USER_FORGOT_PASSWORD, forgotPassword),
    takeLatest(AUTH_USER_RESET_PASSWORD, resetPassword),
    takeLatest(AUTH_GET_INFO, getInfo),
    takeLatest(AUTH_LOGOUT, logout),
  ]

  yield all(filteredSagas)
}
