import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useParams, useNavigate, generatePath } from 'react-router-dom'
import { generateKey } from '@/utils'
import { ROUTER_PATH } from '@/constants/router'
import {
  Container,
  Box,
  Paper,
  Stack,
  Grid,
  Typography,
  Divider,
  TextField,
  Button,
} from '@mui/material'
import ChangePasswordModal from '@components/Profile/ChangePassword'
import UploadImage from '@components/common/UploadImage'
import { FormType } from '@/interfaces'
import {
  FETCH_PROFILE_DETAIL,
  EDIT_PROFILE,
} from '@/redux/reducers/profile.slice'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import SelectCommon from '@/components/common/SelectCommon'

function Profile() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const params = useParams()
  const { isLoading } = useAppSelector(state => state.app)
  const [formType, setFormType] = useState<FormType>('view')
  const [toggleModal, setToggleModal] = useState(false)
  const { profile } = useAppSelector(state => state.profile)

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const Schema = Yup.object().shape({
    name: Yup.string()
      .required(t('validation.name_required'))
      .matches(/^[0-9a-zA-Z-_]+$/, t('validation.username_valid'))
      .min(1, t('validation.username_valid'))
      .max(25, t('validation.valid_max_25')),
    birthday: Yup.string().required(t('validation.birthday_required')),
    sex: Yup.string().required(t('validation.sex_required')),
    address: Yup.string()
      .required(t('validation.address_required'))
      .min(1, t('validation.username_valid'))
      .max(255, t('validation.valid_max_255')),
    tel: Yup.string()
      .required(t('validation.phone_number_required'))
      .matches(phoneRegExp, t('validation.phone_number_valid'))
      .min(1, t('validation.username_valid'))
      .max(25, t('validation.valid_max_25')),
    email: Yup.string()
      .email(t('validation.email_invalid'))
      .required(t('validation.email_required'))
      .max(50, t('validation.valid_max_50')),
    memo: Yup.string()
      .required(t('validation.email_required'))
      .max(500, t('validation.more_info_required')),
  })
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: profile,
  })

  const handleNavigate = () => {
    if (formType === 'view') {
      navigate(
        generatePath(ROUTER_PATH.PROFILE_EDIT, {
          action: 'edit',
        })
      )
    } else {
      navigate(ROUTER_PATH.PROFILE)
    }
  }
  const onSubmit = (data: any) => {
    const dataSubmit = {
      name: data.name,
      birthday: data.birthday,
      sex: data.sex,
      address: data.address,
      tel: data.tel,
      email: data.email,
      avatar_id: data.avatar,
      memo: data.memo,
      old_updated_at: data.updated_at,
    }
    if (typeof data.avatar !== 'number') delete dataSubmit.avatar_id

    dispatch(EDIT_PROFILE(dataSubmit))
    if (!isLoading) handleNavigate()
  }

  const groupBox2 = profile.group_box1.flatMap(item => item.group_box2)
  const isBlank = profile.group_box1.length <= 0

  useEffect(() => {
    dispatch(FETCH_PROFILE_DETAIL())
  }, [])

  useEffect(() => {
    reset(profile)
  }, [profile.id])

  useEffect(() => {
    if (params.action === 'edit') setFormType('edit')
    else setFormType('view')
  }, [params])

  return (
    <>
      <Typography mb={1} variant="h6" fontWeight="bold">
        {formType === 'view' && t('profile.title_view')}
        {formType === 'edit' && t('profile.title_edit')}
      </Typography>
      <Box component={Paper} pt={2} pb={1}>
        <Container fixed>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            {formType !== 'view' && (
              <Box sx={{ ml: 2, mb: 2 }}>
                {t('monitoring.monitor_detail.please_enter')}
              </Box>
            )}
            <Grid container spacing={10}>
              <Grid item xs={7}>
                <Stack direction="column" spacing={2}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        id="name"
                        disabled={formType === 'view'}
                        label={t('profile.name')}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Stack direction="row" spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Controller
                        name="birthday"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            disabled={formType === 'view'}
                            views={['year', 'month', 'day']}
                            label={t('profile.birthday')}
                            maxDate={new Date()}
                            value={field.value}
                            onChange={newValue => {
                              field.onChange(newValue)
                            }}
                            renderInput={param => (
                              <TextField id="birthday" {...param} />
                            )}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <Controller
                      name="sex"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <SelectCommon
                          api="sex_type"
                          label={t('profile.gender')}
                          isDisable={formType === 'view'}
                          defaultValueMaster={field.value.toString()}
                          handleChange={field.onChange}
                          errMessage={error?.message}
                        />
                      )}
                    />
                  </Stack>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        id="address"
                        disabled={formType === 'view'}
                        label={t('profile.address')}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="tel"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        id="tel"
                        disabled={formType === 'view'}
                        label={t('profile.phoneNumber')}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        id="email"
                        disabled={formType === 'view'}
                        label={t('profile.email')}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="role"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <SelectCommon
                        api="monitor_role"
                        label={t('profile.role')}
                        isDisable
                        defaultValueMaster={field.value.toString()}
                        handleChange={field.onChange}
                        errMessage={error?.message}
                      />
                    )}
                  />
                  {profile.role !== 1 && (
                    <Controller
                      name="parent_name"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          id="parent_name"
                          disabled={formType !== 'create'}
                          label={t('profile.administrator')}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                  <Box
                    component={Paper}
                    elevation={1}
                    sx={{ height: 190, overflow: 'auto' }}
                  >
                    {profile.role !== 1 && (
                      <Grid container spacing={4} p={1}>
                        <Grid item xs={6}>
                          <Typography variant="body1" sx={{ m: 1 }}>
                            {t('profile.group_1')}
                          </Typography>
                          {!isBlank &&
                            profile.group_box1.map((item, index) => (
                              <Stack
                                key={generateKey('group_1', index)}
                                direction="column"
                                spacing={0.5}
                              >
                                <span>{item.name}</span>
                                <Divider />
                              </Stack>
                            ))}
                          {isBlank && (
                            <Typography variant="body1" sx={{ m: 1 }}>
                              ー
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1" sx={{ m: 1 }}>
                            {t('profile.group_2')}
                          </Typography>
                          {!isBlank &&
                            groupBox2.map((item, index) => (
                              <Stack
                                key={generateKey('group_2', index)}
                                direction="column"
                                spacing={0.5}
                              >
                                <span>{item.name}</span>
                                <Divider />
                              </Stack>
                            ))}
                          {isBlank && (
                            <Typography variant="body1" sx={{ m: 1 }}>
                              ー
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                  {formType === 'edit' && (
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => setToggleModal(!toggleModal)}
                        sx={{ width: '30%' }}
                      >
                        {t('profile.changePassword')}
                      </Button>
                    </Stack>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={5}>
                <Stack direction="column" spacing={3} width="100%">
                  {formType !== 'view' && (
                    <Box sx={{ ml: 1 }}>
                      {t('monitoring.monitor_detail.please_select')}
                    </Box>
                  )}
                  <Stack direction="row" spacing={3} alignItems="start">
                    <Controller
                      name="avatar"
                      control={control}
                      render={({ field }) => (
                        <UploadImage
                          idUpload="avatar"
                          size={{ height: '255px', width: '178px' }}
                          isDelete={formType !== 'view'}
                          type="MONITOR_AVATAR"
                          formType={formType}
                          imageURL={formType !== 'create' ? profile.avatar : ''}
                          onChange={field.onChange}
                          maxSize={1.5}
                        />
                      )}
                    />
                  </Stack>
                  <Controller
                    name="memo"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        disabled={formType === 'view'}
                        value={field.value.length > 0 ? field.value : 'ー'}
                        id="memo"
                        multiline
                        minRows={12}
                        label={t('monitoring.monitor_detail.detail')}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            {formType === 'view' && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={6}
                mt={2}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate(ROUTER_PATH.DASHBOARD)}
                >
                  {t('profile.back_btn')}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleNavigate}
                >
                  {t('profile.edit_btn')}
                </Button>
              </Stack>
            )}
            {formType !== 'view' && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={6}
                mt={2}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate(ROUTER_PATH.PROFILE)}
                >
                  {t('profile.back_btn')}
                </Button>
                <Button variant="contained" size="small" type="submit">
                  {t('profile.save_btn')}
                </Button>
              </Stack>
            )}
          </Box>
        </Container>
      </Box>
      <ChangePasswordModal isOpen={toggleModal} toggleModal={setToggleModal} />
    </>
  )
}

export default React.memo(Profile)
