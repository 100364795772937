/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Table from 'antd/es/table'
import { ColumnsType } from 'antd/lib/table/interface'

import { useTranslation } from 'react-i18next'
import {
  Box,
  Grid,
  Stack,
  Typography,
  Paper,
  TextField,
  Button,
  IconButton,
  Avatar,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  ListItemText,
} from '@mui/material'
import { Edit, Delete } from '@mui/icons-material'
import { useNavigate, generatePath } from 'react-router-dom'
import { MESSAGE_CONTENT, ROUTER_PATH } from '@/constants'
import { IMonitorRecord } from '@/interfaces'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  FETCH_MONITOR_LIST,
  FETCH_GROUP,
  FETCH_LOWER_ROLES,
  DELETE_MONITOR,
} from '@/redux/reducers/monitor.slice'
import dayjs from 'dayjs'
import { DEFAULT_LANGUAGE } from '@/constants/common'
import { getValueMasterData } from '@/utils/helper'
import useMasterData from '@/hooks/useMasterData'
import ConfirmDialog from '@components/common/ConfirmDialog'

function MonitorManagement() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { monitorList, lowerRole } = useAppSelector(state => state.monitor)
  const { languageMaster, isLoading, message } = useAppSelector(
    state => state.app
  )
  const roles = useMasterData('monitor_role')
  const roleList = getValueMasterData(roles)
  const [queryParamsRequest, setQueryParamsRequest] = useState({
    all: '',
    role: '',
    group_1: [] as any[],
    group_2: [] as any[],
    sort: '',
    page: '1',
  })

  const dataSource = monitorList.data?.map(item => ({
    ...item,
    key: item.id,
  }))

  const [toggleDialog, setToggleDialog] = useState<boolean>(false)

  const [deleteId, setDeleteId] = useState<string | undefined>('')

  const handleToggleDialog = () => setToggleDialog(!toggleDialog)

  const handleDelete = () => {
    dispatch(DELETE_MONITOR(deleteId?.toString()))
  }

  useEffect(() => {
    if (message.content === MESSAGE_CONTENT.DELETE_SUCCESS) {
      handleToggleDialog()
    }
  }, [message])

  const columns: ColumnsType<IMonitorRecord> = [
    {
      key: 'avatar',
      dataIndex: 'avatar',
      title: '',
      align: 'center',
      render: (_, record) => (
        <Avatar
          src={record.avatar || ''}
          sx={{ height: 60, width: 60, margin: 1 }}
        />
      ),
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: t('monitor_management.name'),
    },
    {
      key: 'address',
      dataIndex: 'address',
      title: t('monitor_management.address'),
    },
    {
      key: 'tel',
      dataIndex: 'tel',
      title: t('monitor_management.phoneNumber'),
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: t('monitor_management.email'),
    },
    {
      key: 'role',
      dataIndex: 'role',
      title: t('monitor_management.role'),
      render: record => (
        <span>
          {languageMaster === DEFAULT_LANGUAGE
            ? roleList[record]?.title_jp
            : roleList[record]?.title_en}
        </span>
      ),
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('monitor_management.registration_date'),
      sorter: true,
      render: record => <span>{dayjs(record).format('YYYY/MM/DD')}</span>,
    },
    {
      title: t('monitor_management.actions'),
      render: record => (
        <Stack direction="row" p={0}>
          <IconButton
            onClick={() => {
              handleToggleDialog()
              setDeleteId(record.id)
            }}
          >
            <Delete />
          </IconButton>
          <IconButton
            onClick={() => {
              navigate(
                generatePath(ROUTER_PATH.MONITOR_ACTION, {
                  id: record.id,
                  action: 'edit',
                })
              )
            }}
          >
            <Edit />
          </IconButton>
        </Stack>
      ),
    },
  ]

  const onSubmit = (data: any) => {
    const { group1, group2, role, all } = data
    setQueryParamsRequest({
      ...queryParamsRequest,
      all,
      role,
      group_1: group1,
      group_2: group2,
    })
  }

  const defaultValues = {
    all: '',
    role: '',
    group1: [] as any[],
    group2: [] as any[],
  }
  const { handleSubmit, control } = useForm({
    defaultValues,
  })

  const [availableGroup, setAvailableGroup] = React.useState<{
    group_1: any[]
    group_2: any
  }>({
    group_1: [],
    group_2: [],
  })

  useEffect(() => {
    setAvailableGroup({
      group_1: monitorList.availableInfo?.group1,
      group_2: monitorList.availableInfo?.group2,
    })
  }, [monitorList])

  const filterDataRequest = (pagination: any, _: any, sorter: any) => {
    let queryParamsClone = { ...queryParamsRequest }
    queryParamsClone = { ...queryParamsClone, page: pagination.current }
    if (sorter.column) {
      if (sorter.order === 'ascend')
        queryParamsClone = {
          ...queryParamsClone,
          sort: `${sorter.column.key}:ASC`,
        }
      else
        queryParamsClone = {
          ...queryParamsClone,
          sort: `${sorter.column.key}:DESC`,
        }
    } else
      queryParamsClone = {
        ...queryParamsClone,
        sort: '',
      }
    setQueryParamsRequest(queryParamsClone)
  }

  useEffect(() => {
    dispatch(FETCH_MONITOR_LIST(queryParamsRequest))
  }, [queryParamsRequest])

  useEffect(() => {
    dispatch(FETCH_MONITOR_LIST({}))
    dispatch(FETCH_LOWER_ROLES())
    dispatch(FETCH_GROUP())
  }, [])

  return (
    <Box className="monitor-list-container" p={1}>
      <Typography variant="h6" mb={2}>
        {t('monitor_management.title')}
      </Typography>
      <Grid container direction="column" spacing={3}>
        <Grid container item spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Paper elevation={2} sx={{ borderRadius: '20px', width: '100%' }}>
              <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Stack direction="row" spacing={4} p={2} alignItems="center">
                  <Controller
                    name="group1"
                    control={control}
                    render={({ field }) => (
                      <FormControl>
                        <InputLabel id="group1">Group 1</InputLabel>
                        <Select
                          labelId="group1"
                          multiple
                          {...field}
                          renderValue={selected =>
                            availableGroup.group_1
                              ?.filter((group: any) =>
                                selected.includes(group.id)
                              )
                              ?.map((item: any) => item.name)
                              .join(', ')
                          }
                        >
                          {availableGroup.group_1?.map((group: any) => (
                            <MenuItem key={group.name} value={group.id}>
                              <Checkbox
                                checked={field.value.includes(group.id)}
                              />
                              <ListItemText primary={group.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="group2"
                    control={control}
                    render={({ field }) => (
                      <FormControl>
                        <InputLabel id="group2">Group 2</InputLabel>
                        <Select
                          labelId="group2"
                          multiple
                          {...field}
                          renderValue={selected =>
                            availableGroup.group_2
                              ?.filter((group: any) =>
                                selected.includes(group.id)
                              )
                              ?.map((item: any) => item.name)
                              .join(', ')
                          }
                        >
                          {availableGroup.group_2?.map((group: any) => (
                            <MenuItem key={group.name} value={group.id}>
                              <Checkbox
                                checked={field.value.includes(group.id)}
                              />
                              <ListItemText primary={group.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label={t('monitor_management.role')}
                      >
                        <MenuItem value="">All</MenuItem>
                        {lowerRole.map(role => (
                          <MenuItem key={role.id} value={role.value}>
                            {languageMaster === DEFAULT_LANGUAGE
                              ? role.title_jp
                              : role.title_en}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                  <Controller
                    name="all"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t('monitor_management.all')}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Button variant="contained" type="submit" size="small">
                    {t('monitor_management.search_button')}
                  </Button>
                </Stack>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => navigate(ROUTER_PATH.MONITOR_CREATE)}>
              <Avatar
                sx={{
                  height: '100px',
                  width: '100px',
                  p: 1,
                  backgroundColor: '#3399BD',
                }}
              >
                <Typography variant="body1" fontWeight="bold" color="white">
                  {t('monitor_management.add_monitor')}
                </Typography>
              </Avatar>
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <Table
            loading={isLoading}
            onChange={filterDataRequest}
            columns={columns}
            dataSource={dataSource}
            onRow={record => ({
              onDoubleClick: () => {
                navigate(
                  generatePath(ROUTER_PATH.MONITOR, {
                    id: record.id?.toString(),
                  })
                )
              },
            })}
            pagination={{
              total: monitorList.meta.total,
              showTotal: () =>
                `${
                  monitorList.meta.per_page *
                    (monitorList.meta.current_page - 1) +
                  1
                }-${
                  monitorList.meta.per_page *
                    (monitorList.meta.current_page - 1) +
                  monitorList.data.length
                } / ${monitorList.meta.total}`,
            }}
          />
        </Grid>
        <ConfirmDialog
          dialogTitle={t('monitoring.monitor_detail.delete_monitor')}
          dialogContent={t('monitoring.monitor_detail.delete_description')}
          openDialog={toggleDialog}
          setOpenDialog={handleToggleDialog}
          onConfirmDialog={handleDelete}
          okText={t('monitoring.monitor_detail.btn_delete_monitor')}
          cancelText={t('confirm.cancel')}
        />
      </Grid>
    </Box>
  )
}

export default React.memo(MonitorManagement)
