import { useState, useEffect } from 'react'
import { generateKey, getEnv } from '@/utils'
import { useAppSelector } from '@/redux/hooks'
import { WS_PREFIX } from '@/constants/common'

const io = require('socket.io-client')

export default function useSocket() {
  const [isConnected, setIsConnected] = useState(false)
  const [socket, setSocket] = useState<any>(null)
  const hasRerender = (prevState: any, state: any) => !state.loading

  const { currentUser } = useAppSelector(
    state => state.auth,
    hasRerender
  )

  useEffect(() => {
    const privateRoom = generateKey(WS_PREFIX, currentUser.id)
    const socketIo = io(getEnv('WS_URL'), {
      transports: ['websocket'],
      query: {
        room: ['monitor', privateRoom],
        type: 'staff'
      }
    })

    socketIo.on('connect', () => {
      setIsConnected(socketIo.connected)
    })

    setSocket(socketIo)
  }, [])

  return {
    isConnected,
    socket
  }
}