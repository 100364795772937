import React, { useState, useRef } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
  Divider,
  Button,
  Box,
  Badge,
  Typography,
  IconButton,
  Popover,
  Tooltip,
  ListItemAvatar,
  Avatar,
  ListItemText,
  List,
  ListItemButton,
} from '@mui/material'
import avatar from '@/assets/images/avatar.png'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { ROUTER_PATH } from '@/constants'
import { useTranslation } from 'react-i18next'

const MOCK_NOTIFICATIONS = [
  {
    id: 1,
    title: 'Sylvan King',
    description: 'answered to your comment on the Rikkei',
    avatar: '@/assets/images/avatar.png',
    type: 'friend_interactive',
    createdAt: `1 day`,
    isUnRead: true,
  },
  {
    id: 2,
    title: 'Sylvan King',
    description: 'answered to your comment on the Rikkei',
    avatar: '@/assets/images/avatar.png',
    type: 'friend_interactive',
    createdAt: `1 day`,
    isUnRead: false,
  },
  {
    id: 3,
    title: 'Sylvan King',
    description: 'answered to your comment on the Rikkei',
    avatar: '@/assets/images/avatar.png',
    type: 'friend_interactive',
    createdAt: `1 day`,
    isUnRead: true,
  },
  {
    id: 4,
    title: 'Sylvan King',
    description: 'answered to your comment on the Rikkei',
    avatar: '@/assets/images/avatar.png',
    type: 'friend_interactive',
    createdAt: `1 day`,
    isUnRead: false,
  },
]

function renderContent(notification: any) {
  const title = (
    <Typography>
      {notification.title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: 'text.secondary' }}
      >
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  )

  return {
    title,
  }
}

function NotificationItem({ notification }: any) {
  const { title } = renderContent(notification)

  return (
    <ListItemButton
      to="#"
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: '#919eab29',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar src={avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Box
              component={AccessTimeIcon}
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {notification.createdAt}
          </Typography>
        }
      />
    </ListItemButton>
  )
}

function Notifications() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const ref = useRef(null)
  const [open, setOpen] = useState(false)
  const [notifications, setNotifications] = useState(MOCK_NOTIFICATIONS)
  const totalUnRead = notifications.filter(
    item => item.isUnRead === true
  ).length

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleViewAll = () => {
    setOpen(false)
    navigate(ROUTER_PATH.NOTIFICATIONS)
  }

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map(notification => ({
        ...notification,
        isUnRead: false,
      }))
    )
  }

  return (
    <>
      <Box sx={{ display: 'inline-flex' }} onClick={handleOpen}>
        <IconButton size="large" ref={ref}>
          <Badge badgeContent={totalUnRead} color="error">
            <NotificationsNoneIcon />
          </Badge>
        </IconButton>
      </Box>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={ref.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mr: 2,
            overflow: 'hidden',
            width: 400,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={{ fontWeight: 600, lineHeight: 1.5 }}>
              {t('header.notifications')}
            </Typography>
            <Typography sx={{ color: 'text.disabled', fontSize: 14 }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>
          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <Box
                sx={{ display: 'inline-flex' }}
                onClick={handleMarkAllAsRead}
              >
                <IconButton size="large">
                  <DoneAllIcon
                    width={20}
                    height={20}
                    sx={{ color: 'text.primary' }}
                  />
                </IconButton>
              </Box>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Box sx={{ height: { xs: 440, overflow: 'hidden', flexGrow: 1 } }}>
          <List disablePadding>
            {notifications.map(notification => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </List>
        </Box>

        <Divider />

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            sx={{
              fontWeight: 600,
              fontSize: 14,
              height: 36,
              textTransform: 'none',
            }}
            onClick={handleViewAll}
          >
            {t('header.view_all')}
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default React.memo(Notifications)
