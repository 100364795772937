import React, { useState, useRef } from 'react'
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
  Popover,
} from '@mui/material'
import { LANGS, LANGUAGE_STORE_KEY } from '@/constants'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '@/redux/hooks'
import { SET_LANGUAGE } from '@/redux/reducers/app.slice'

function Language() {
  const { i18n } = useTranslation();
  const langStorage = localStorage.getItem(LANGUAGE_STORE_KEY);
  const currentLang = LANGS.find((lang) => lang.value === langStorage) || LANGS[0];
  const dispatch = useAppDispatch()
  const [language, setLanguage] = useState(currentLang)
  const ref = useRef(null)
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleChangeLanguage = (newlang: any) => {
    setLanguage(newlang)
    i18n.changeLanguage(newlang.value)
    localStorage.setItem(LANGUAGE_STORE_KEY, newlang.value)
    dispatch(SET_LANGUAGE(newlang.value))
    handleClose()
  }

  return (
    <>
      <Box
        sx={{
          display: 'inline-flex', padding: 0, width: 44, height: 44,
        }}
        onClick={handleOpen}
      >
        <IconButton ref={ref}>
          <img src={language.icon} alt={language.label} />
        </IconButton>
      </Box>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={ref.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mr: 2,
            mt: 0.5,
            overflow: 'hidden',
            width: 180
          },
        }}
      >
        {LANGS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === language.value}
            onClick={() => handleChangeLanguage(option)}
            sx={{ py: 1, px: 2.5, my: 0.5 }}
          >
            <ListItemIcon sx={{ mr: 3 }}>
              <img src={option.icon} alt={option.label} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </Popover>
    </>
  )
}

export default React.memo(Language)
