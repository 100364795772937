import * as React from 'react'
import { Box, Grid, Stack, Button, Typography, IconButton } from '@mui/material'
import { Phone, ChatBubble } from '@mui/icons-material'
import '@/assets/styles/pages/boxDetails.scss'
import { useTranslation } from 'react-i18next'
import { useNavigate, generatePath, useParams } from 'react-router-dom'
import { ROUTER_PATH } from '@/constants'
import CommentBox from '@/components/Monitoring/BoxDetails/CommentBox'
import Calling from '@/components/Monitoring/BoxDetails/Calling'
import Camera from '@/components/Monitoring/BoxDetails/Camera'
import CameraCalling from '@/components/Monitoring/BoxDetails/CameraCalling'
import ControlPanel from '@/components/Monitoring/BoxDetails/ControlPanel'
import { IListCamera } from '@/interfaces'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { FETCH_COMMENT_BOX_DETAILS } from '@/redux/reducers/monitoring.slice'
import useSocket from '@/hooks/useSocket'
import { HANDLE_CALL, LEAVE_CALL } from '@/redux/reducers/callVideo.slice'
import { getEnv } from '@/utils'

const ROOM_NAME = getEnv('DEBUG_ROOM_NAME')

const SHOP_ID = getEnv('DEBUG_SHOP_ID')

function BoxDetails() {
  const params = useParams()

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { isHaveCall, isCalling } = useAppSelector(state => state.callVideo)

  const [isOpenComment, setIsOpenComment] = React.useState<boolean>(false)

  const [isCall, setIsCall] = React.useState<boolean>(false)

  const [commentContent, setCommentContent] = React.useState<string>('')

  const [isCallWait, setIsCallWait] = React.useState<boolean>(false)

  const [isMicrophoneOn, setIsMicrophoneOn] = React.useState<boolean>(true)

  const [isVideoOn, setIsVideoOn] = React.useState<boolean>(true)

  const { socket } = useSocket()

  const { currentUser } = useAppSelector(state => state.auth)

  const [camera, setCamera] = React.useState<Array<IListCamera>>([
    {
      camera_id: 1,
      statusCamera: {
        status: true,
        isZoom: false,
      },
    },
    {
      camera_id: 2,
      statusCamera: {
        status: false,
        isZoom: false,
      },
    },
  ])

  const navigate = useNavigate()

  const onSubmit = () => {
    // TODO data: any
  }

  const handleCall = () => {
    const cameraClone = [...camera]
    cameraClone.forEach(item => {
      item.statusCamera.isZoom = false
    })
    socket.emit('monitor_call_to_mirror', {
      mirrorCode: ROOM_NAME,
      currentUserId: currentUser.id,
    })
    const data = {
      companyCode: 'GIN',
      shopId: SHOP_ID,
      mirrorCode: ROOM_NAME,
      eventInformation: [
        {
          eventPageName: '32_呼び出し',
          eventPageId: 'invited-by-staff',
          eventButtonId: '',
          eventType: '',
          eventUpdown: '',
          eventValue: '',
        },
      ],
      courseDetailSetting: [],
    }
    socket.emit('customize_course', data)
    setIsOpenComment(false)
    setIsCallWait(true)
    dispatch(HANDLE_CALL(true))
  }

  const handleComment = () => {
    const cameraClone = [...camera]
    cameraClone.forEach(item => {
      item.statusCamera.isZoom = false
    })
    setCamera(cameraClone)
    setIsOpenComment(!isOpenComment)
  }

  React.useEffect(() => {
    dispatch(FETCH_COMMENT_BOX_DETAILS())
  }, [])

  React.useEffect(() => {
    if (isHaveCall) {
      setIsCall(true)
    } else {
      setIsCall(false)
    }
  }, [isHaveCall])

  React.useEffect(() => {
    if (!isCalling) {
      setIsCallWait(false)
    }
  }, [isCalling])

  return (
    <Box p={1} sx={{ height: '100%' }}>
      <Typography mb={2} variant="h6" fontWeight="bold">
        {t('monitoring.box_details.title')}
      </Typography>
      <Box className="box-details-container">
        <Stack direction="column" justifyContent="space-between" height="100%">
          <Box>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography className="box-details-title">BX301</Typography>
              <Typography className="box-details-using">
                {t('monitoring.box_details.user_id_is_in_use')} (13:00 ～ 15:00)
              </Typography>
              {isCall && (
                <Typography className="box-details-calling">
                  {t('monitoring.box_details.in_a_call_with_the_user_id')}
                </Typography>
              )}
              <Button
                className="btn-history"
                onClick={() =>
                  navigate(
                    generatePath(ROUTER_PATH.MONITORING_HISTORY, {
                      id: params.id,
                    })
                  )
                }
              >
                {t('monitoring.box_details.button.history')}
              </Button>
            </Stack>
            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={8}>
                {isCall ? (
                  // Have Calling
                  <CameraCalling
                    isMicrophoneOn={isMicrophoneOn}
                    setIsMicrophoneOn={setIsMicrophoneOn}
                    isVideoOn={isVideoOn}
                    setIsVideoOn={setIsVideoOn}
                    leaveCall={() => {
                      setIsCall(false)
                      dispatch(LEAVE_CALL())
                      socket.emit('monitor_hang_customer', {
                        mirrorCode: ROOM_NAME,
                        staffId: currentUser.id.toString(),
                      })
                    }}
                  />
                ) : (
                  // No calling
                  <Camera
                    camera={camera}
                    setCamera={setCamera}
                    setIsOpenComment={() => setIsOpenComment(false)}
                  />
                )}
              </Grid>
              <ControlPanel
                onSubmit={onSubmit}
                setIsOpenComment={() => setIsOpenComment(false)}
              />
            </Grid>
          </Box>
          <Stack direction="column" spacing={3}>
            {!isCall && (
              <Stack
                direction="row"
                justifyContent="center"
                width="50%"
                spacing={3}
              >
                <IconButton className="button-call" onClick={handleCall}>
                  <Phone />
                </IconButton>
                <ChatBubble
                  className="button-message"
                  onClick={handleComment}
                />
              </Stack>
            )}
            <Stack direction="row" justifyContent="center">
              <Button
                variant="outlined"
                sx={{ borderRadius: 25, width: '13%' }}
                className="button-back"
                onClick={() => navigate(ROUTER_PATH.MONITORING)}
              >
                {t('monitoring.box_details.button.return')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
        {isOpenComment && (
          <CommentBox
            commentContent={commentContent}
            setCommentContent={setCommentContent}
          />
        )}
        {isCallWait && (
          <Calling
            cancelCalling={() => {
              setIsCallWait(false)
              dispatch(HANDLE_CALL(false))
              // TODO: cancel call
              // socket.emit('monitor_hang_customer', {
              //   mirrorCode: '5010',
              //   staffId: currentUser.id.toString(),
              // })
            }}
            isMicrophoneOn={isMicrophoneOn}
            setIsMicrophoneOn={setIsMicrophoneOn}
            isVideoOn={isVideoOn}
            setIsVideoOn={setIsVideoOn}
          />
        )}
      </Box>
    </Box>
  )
}
export default React.memo(BoxDetails)
