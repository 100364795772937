import { all, takeLatest, put, call, ForkEffect } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  apiGetProfileDetail,
  apiEditProfileDetail,
  apiEditPassWordProfileDetail
} from '@/api/profile'
import { IEditProfile, IResetPassword } from '@/interfaces'
import { AUTH_USER_CHECK_TOKEN } from '@/redux/reducers/users/auth.slice'
import { FETCH_PROFILE_DETAIL, SET_PROFILE_DETAIL, EDIT_PROFILE, EDIT_RESET_PASSWORD_PROFILE } from '@/redux/reducers/profile.slice'
import { SET_LOADING, SET_MESSAGE, RESET_MESSAGE } from '@/redux/reducers/app.slice'
import { handleMessageError } from '@/utils'

function* getProfileDetail() {
  try {
    yield put(SET_LOADING(true))
    const response: AxiosResponse = yield call(apiGetProfileDetail)
    const { data } = response
    yield put(SET_PROFILE_DETAIL({ ...data }))
  } catch (err) {
    yield put(SET_MESSAGE({ type: 'error', content: handleMessageError(err) }))
  }
  finally {
    yield put(SET_LOADING(false))
  }
}

function* editProfile(action: PayloadAction<IEditProfile>) {
  try {
    yield put(SET_LOADING(true))
    const { payload } = action
    yield apiEditProfileDetail(payload)
    yield put(FETCH_PROFILE_DETAIL())
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* editPassWordProfile(action: PayloadAction<IResetPassword>) {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const { payload } = action
    yield apiEditPassWordProfileDetail(payload)
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: 'reset_password_success',
      })
    )
    yield put(AUTH_USER_CHECK_TOKEN('success'))
  } catch (err) {
    yield put(
      SET_MESSAGE({
        type: 'error',
        content: handleMessageError(err),
      })
    )
  } finally {
    yield put(SET_LOADING(false))
  }
}

export default function* profileSaga() {
  const filteredSagas: ForkEffect[] = [
    takeLatest(FETCH_PROFILE_DETAIL, getProfileDetail),
    takeLatest(EDIT_PROFILE, editProfile),
    takeLatest(EDIT_RESET_PASSWORD_PROFILE, editPassWordProfile)
  ]
  yield all(filteredSagas)
}
