import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import PageLoading from '@components/common/PageLoading'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import { ROUTER_PATH, TYPE_MESSAGE } from '@/constants'
import { useAppSelector } from '@/redux/hooks'
import { useTranslation } from 'react-i18next'

const theme = createTheme()

export default function AuthLayout() {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { isLoading, message } = useAppSelector(state => state.app)
  const { currentUser } = useAppSelector(state => state.auth)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (message.type !== '' && !currentUser.id) {
      if (message.content !== '')
        enqueueSnackbar(message.content, {
          variant: message.type,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        })
      else if (message.type === TYPE_MESSAGE.SUCCESS)
        enqueueSnackbar(t('message.default_success'), {
          variant: message.type,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        })
      else
        enqueueSnackbar('message.default_error', {
          variant: message.type,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        })
    }
  }, [message])

  useEffect(() => {
    if (pathname === '/') {
      navigate(ROUTER_PATH.AUTH.SIGN_IN)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Box component="main">
        <CssBaseline />
        <Outlet />
      </Box>
      <PageLoading show={isLoading} />
    </ThemeProvider>
  )
}
