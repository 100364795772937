import '@/assets/styles/pages/reset.scss'
import * as Yup from 'yup'
import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { AUTH_USER_RESET_PASSWORD } from '@/redux/reducers/users/auth.slice'
import { useNavigate, useLocation } from 'react-router-dom'
import { AuthResetPassword } from '@/interfaces'
import { Grid, Button, Box, TextField, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { ROUTER_PATH } from '@/constants'

function ResetPassword() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const tokenExpired = useAppSelector(state => state.auth.tokenExpired)

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const token = query.get('token')
  const navigate = useNavigate()
  const defaultValues = {
    password: '',
    confirm_password: '',
  }
  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('validation.password_required'))
      .min(8, t('validation.valid_min_8'))
      .max(32, t('validation.password_valid_max')),
    confirm_password: Yup.string()
      .required(t('validation.password_required'))
      .min(8, t('validation.valid_min_8'))
      .max(32, t('validation.password_valid_max'))
      .oneOf([Yup.ref('password')], t('validation.password_not_match')),
  })

  const onSubmit = (data: AuthResetPassword) => {
    dispatch(
      AUTH_USER_RESET_PASSWORD({
        password: data.password,
        confirm_password: data.confirm_password,
        token,
      })
    )
  }
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues,
  })

  const handleLogin = () => {
    navigate(ROUTER_PATH.AUTH.SIGN_IN)
  }

  const handleForgotPassword = () => {
    navigate(ROUTER_PATH.AUTH.FORGOT_PASSWORD)
  }

  return (
    <Box className="reset-container">
      <Grid container>
        <Grid item xs={6} p={0} className="left-wrapper" />
        <Grid item xs={6} className="right-wrapper">
          {tokenExpired === '' && (
            <Box className="form-reset">
              <Typography variant="h4" className="title">
                {t('reset_password.title')}
              </Typography>
              <Typography pt={2}>
                <Trans parent="span" i18nKey="reset_password.description" />
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                sx={{ mt: 1 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          type="password"
                          margin="normal"
                          fullWidth
                          id="password"
                          label={t('reset_password.label_newPassword')}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} sm={12}>
                    <Controller
                      name="confirm_password"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          type="password"
                          margin="normal"
                          fullWidth
                          id="confirm_password"
                          label={t('reset_password.label_confirm_new_password')}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {t('reset_password.title')}
                </Button>
              </Box>
            </Box>
          )}

          {tokenExpired === 'success' && (
            <Box className="form-reset">
              <Typography variant="h4" className="title">
                {t('reset_password.password_changed')}
              </Typography>
              <Typography pt={2}>
                <Trans parent="span" i18nKey="reset_password.description_success" />
              </Typography>

              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleLogin}
              >
                {t('reset_password.btn_login')}
              </Button>
            </Box>
          )}
          {tokenExpired === 'time-out' && (
            <Box className="form-reset">
              <Typography variant="h4" className="title">
                {t('reset_password.error')}
              </Typography>
              <Typography pt={2}>
                <Trans parent="span" i18nKey="reset_password.description_error" />
              </Typography>

              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleLogin}
              >
                {t('reset_password.btn_login')}
              </Button>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleForgotPassword}
              >
                {t('reset_password.btn_forgot_password')}
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(ResetPassword)
