import axios from 'axios'

import { AUTH_USER_TOKEN } from '@/constants/common'

export const setUserToken = (token: string) => {
  localStorage.setItem(AUTH_USER_TOKEN, token)
}

export const getUserToken = () => localStorage.getItem(AUTH_USER_TOKEN)

export const removeUserToken = () => {
  localStorage.removeItem(AUTH_USER_TOKEN)
}

export const setSessionToken = (token: string) =>
  sessionStorage.setItem(AUTH_USER_TOKEN, token)

export const getSessionToken = () => sessionStorage.getItem(AUTH_USER_TOKEN)

export const removeSessionToken = () =>
  sessionStorage.removeItem(AUTH_USER_TOKEN)

export const handleMessageError = (err: any) => {
  let errMessage = ''
  if (axios.isAxiosError(err)) {
    if (err.response?.data.errors && err.response?.data.errors.length > 0) {
      errMessage = err.response?.data.errors[0].message
    } else {
      errMessage = err.response?.data.message
        ? err.response?.data.message
        : err.message
    }
  }
  return errMessage
}

export const getValueMasterData = (array: Array<any>) => {
  const initialValue = {}
  return array.reduce(
    (obj: any, item: any) => ({
      ...obj,
      [Number(item.value)]: item,
    }),
    initialValue
  )
}
export const generateKey = (prefix: string, index: number) =>
  `${prefix}_${index}`

export const getEnv = (key: string) => process.env[`REACT_APP_${key}`] ?? ''

export const showTotalPaginationTable = (
  perPage: number,
  currentPage: number,
  arrayLength: number,
  total: number
): string =>
  `${perPage * (currentPage - 1) + 1}-${
    perPage * (currentPage - 1) + arrayLength
  } / ${total}`
