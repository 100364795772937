import client from '@/api/axiosClient'
import {
  AuthRegister,
  AuthSignIn,
  AuthForgotPassword,
  AuthResetPassword,
} from '@/interfaces'

// Sign-in
export const apiUserSignIn = (data: AuthSignIn) =>
  client.post('monitors/login', { email: data.email, password: data.password })

// Register
export const apiUserRegister = (data: AuthRegister) =>
  client.post('monitors/register', data)

// Sign-out
export const apiUserSignOut = () => client.post('monitors/logout')

// Forgot-password
export const apiUserForgotPassword = (data: AuthForgotPassword) =>
  client.post('monitors/forgot-password', data)

// Reset-password
export const apiUserResetPassword = (data: AuthResetPassword) =>
  client.post('monitors/reset-password', data)
