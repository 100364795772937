import React, { useState } from 'react'
import * as Yup from 'yup'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import {
  Grid,
  Button,
  Box,
  TextField,
  Typography,
  Modal,
  IconButton,
  Stack,
  InputAdornment,
} from '@mui/material'
import { Close, Visibility, VisibilityOff } from '@mui/icons-material'
import { IResetPassword } from '@/interfaces'
import { AUTH_LOGOUT } from '@/redux/reducers/users/auth.slice'
import { EDIT_RESET_PASSWORD_PROFILE } from '@/redux/reducers/profile.slice'
import { ROUTER_PATH } from '@/constants'
import { Trans, useTranslation } from 'react-i18next'

interface ModalProps {
  isOpen: boolean
  toggleModal(open: any): void
}

function ChangePasswordModal({ isOpen, toggleModal }: ModalProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const handleToggleModal = () => toggleModal(false)
  const message = useAppSelector(state => state.app.message)
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  })

  const handleShowCurrentPassword = () =>
    setShowPassword({
      ...showPassword,
      currentPassword: !showPassword.currentPassword,
    })
  const handleShowNewPassword = () =>
    setShowPassword({
      ...showPassword,
      newPassword: !showPassword.newPassword,
    })
  const handleShowConfirmNewPassword = () =>
    setShowPassword({
      ...showPassword,
      confirmNewPassword: !showPassword.confirmNewPassword,
    })

  const defaultValues = {
    current_password: '',
    new_password: '',
    confirm_new_password: '',
  }

  const ResetPasswordSchema = Yup.object().shape({
    current_password: Yup.string()
      .required(t('validation.password_required'))
      .min(8, t('validation.valid_min_8'))
      .max(32, t('validation.valid_max_32')),
    new_password: Yup.string()
      .required(t('validation.valid_max_32'))
      .min(8, t('validation.valid_min_8'))
      .max(32, t('validation.password_valid')),
    confirm_new_password: Yup.string()
      .required(t('validation.valid_max_32'))
      .min(8, t('validation.valid_min_8'))
      .max(32, t('validation.valid_max_32'))
      .oneOf([Yup.ref('new_password')], t('validation.password_not_match')),
  })

  const onSubmit = (data: IResetPassword) => {
    dispatch(
      EDIT_RESET_PASSWORD_PROFILE(data)
    )
    if (message.type === 'success') {
      dispatch(AUTH_LOGOUT())
      navigate(ROUTER_PATH.AUTH.RESET_PASSWORD)
    }
    toggleModal(!isOpen)
  }

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues,
  })

  return (
    <Modal open={isOpen} onClose={handleToggleModal}>
      <Box className="modal-content" sx={{ minWidth: '480px' }}>
        <Grid container textAlign="center">
          <Grid item xs>
            <Typography className="title" textAlign="center" m={3}>
              {t('profile.changePassword')}
            </Typography>
            <Typography variant="body2" textAlign="center">
              <Trans parent="span" i18nKey="profile.changePassword_advise" />
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleToggleModal} className="close-button">
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack direction="column" spacing={5}>
            <Controller
              name="current_password"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  margin="normal"
                  type={showPassword.currentPassword ? 'text' : 'password'}
                  label={t('profile.currentPassword')}
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowCurrentPassword}>
                          {showPassword.currentPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="new_password"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  margin="normal"
                  type={showPassword.newPassword ? 'text' : 'password'}
                  label={t('profile.newPassword')}
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowNewPassword}>
                          {showPassword.newPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="confirm_new_password"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  margin="normal"
                  type={showPassword.confirmNewPassword ? 'text' : 'password'}
                  label={t('profile.confirmNewPassword')}
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowConfirmNewPassword}>
                          {showPassword.confirmNewPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Button type="submit" fullWidth variant="contained">
              {t('profile.changePassword')}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}

export default React.memo(ChangePasswordModal)
