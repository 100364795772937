import '@/assets/styles/pages/monitoring-history.scss'
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Box, Stack, Button, TextField } from '@mui/material'
import { DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useTranslation } from 'react-i18next'
import { IMonitoringHistoryParams, IMonitoringHistoryVideo } from '@/interfaces'
import { ColumnsType } from 'antd/lib/table/interface'
import Table from 'antd/es/table'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { FETCH_HISTORY_VIDEO } from '@/redux/reducers/monitoring.slice'
import dayjs from 'dayjs'
import { showTotalPaginationTable } from '@/utils'

function TabVideo() {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const [startTime, setStartTime] = useState<Date | null>(null)

  const [endTime, setEndTime] = useState<Date | null>(null)

  const [queryParams, setQueryParams] = useState<IMonitoringHistoryParams>({
    id: '1',
    date_from: '',
    date_to: '',
    page: 1,
    search: '',
  })

  const { listHistoryVideo, metaHistoryVideo } = useAppSelector(
    state => state.monitoring
  )

  const onSubmit = (data: any) => {
    setQueryParams({
      ...queryParams,
      ...data,
      date_from:
        data.date_from !== '' ? dayjs(data.from_date).format('YYYY-MM-DD') : '',
      date_to:
        data.date_to !== '' ? dayjs(data.to_date).format('YYYY-MM-DD') : '',
    })
  }

  const defaultValues = {
    date_from: '',
    date_to: '',
    search: '',
  }

  const { handleSubmit, control } = useForm({
    defaultValues,
  })

  useEffect(() => {
    dispatch(FETCH_HISTORY_VIDEO(queryParams))
  }, [queryParams])

  const columns: ColumnsType<IMonitoringHistoryVideo> = [
    {
      title: t('monitoring_history.date_time'),
      key: 'date',
      align: 'center',
      render: (_, record) =>
        `${dayjs(record.start_time).format('HH:mm')}~${dayjs(
          record.end_time
        ).format('HH:mm')} ${dayjs(record.start_time).format('YYYY/MM/DD')}`,
    },
    {
      title: t('monitoring_history.video'),
      key: 'video',
      render: (_, record) => (
        <Stack justifyContent="center" alignItems="center">
          <video width={75} height={75} style={{ objectFit: 'cover' }}>
            <source src={record.url} type="video/mp4" />
            <track kind="captions" />
          </video>
        </Stack>
      ),
      align: 'center',
    },
    {
      title: t('monitoring_history.customer'),
      dataIndex: 'nickname',
      key: 'nickname',
      align: 'center',
    },
  ]

  const handlePagination = (pagination: any) => {
    setQueryParams({ ...queryParams, page: pagination.current })
  }

  return (
    <Stack direction="column" spacing={3} pl={3} pr={3}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" spacing={3} alignItems="center" p={1}>
          <Stack direction="row" spacing={3} width="100%">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                name="date_from"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onChange={e => {
                      field.onChange(e)
                      setStartTime(e)
                    }}
                    label={t('monitoring_history.from_date')}
                    maxDate={endTime || new Date()}
                    renderInput={params => (
                      <TextField {...params} error={false} />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
            <Box display="flex" alignItems="center">
              ~
            </Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                name="date_to"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onChange={e => {
                      field.onChange(e)
                      setEndTime(e)
                    }}
                    label={t('monitoring_history.to_date')}
                    minDate={startTime}
                    maxDate={new Date()}
                    renderInput={params => (
                      <TextField {...params} error={false} />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
          <Controller
            name="search"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t('monitoring_history.customer')}
                value={field.value}
                inputProps={{ maxLength: 25 }}
              />
            )}
          />
          <Box width="30%">
            <Button
              type="submit"
              variant="contained"
              sx={{ borderRadius: 20 }}
              fullWidth
            >
              {t('monitoring_history.search')}
            </Button>
          </Box>
        </Stack>
      </Box>
      <Table
        columns={columns}
        dataSource={listHistoryVideo}
        scroll={{ y: 515 }}
        onChange={handlePagination}
        pagination={{
          total: metaHistoryVideo.total,
          showTotal: () =>
            showTotalPaginationTable(
              metaHistoryVideo.per_page,
              metaHistoryVideo.current_page,
              listHistoryVideo.length,
              metaHistoryVideo.total
            ),
        }}
      />
    </Stack>
  )
}

export default React.memo(TabVideo)
