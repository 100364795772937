import { useRoutes } from 'react-router-dom'

// routes
import PublicRouter from '@/routes/public'
import PrivateRouter from '@/routes/private'

// ==============================|| ROUTING RENDER ||============================== //
export default function Routes() {
  return useRoutes([
    PublicRouter,
    PrivateRouter,
  ])
}
